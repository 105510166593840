const config = require("./Config");

const global = {};

global.ONE_SECOND_DELAY = 1000;
global.MINUTE_UPDATE_INTERVAL = 60 * global.ONE_SECOND_DELAY;

global.DELAY_FOR_INJECTED_SCRIPTS = 500;

global.VIEWER_STATUS = {
  OK: 0,
  NO_COOKIE: 1,
  ID_SHARING_VIOLATION: 2,
  // UPDATE_INTERVAL_VIOLATION: 3,
  DISPLAY_NOT_FOUND: 4,
  COMPANY_NOT_FOUND: 5,
  BLOCKED: 6,
  CONTENT_NOT_FOUND: 7,
  NOT_SUBSCRIBED: 8,
  NO_CHANGES: -1,
};

global.VIEWER_VERSION = "2-01-202104211949";
global.DATA_SERVER_URL = config.SERVER_URL + "/v2/viewer/{0}/{1}";
global.CHANNEL_SERVER_URL = config.SERVER_URL + "/v2/viewer/display/";
global.GADGET_SERVER_URL = "http://www-open-opensocial.googleusercontent.com/gadgets/ifr";

global.VIEWER_STARTUP_TIMESTAMP = new Date().getTime();
global.VIEWER_UNIQUE_ID = parseInt(Math.random() * 10000) + "_" + global.VIEWER_STARTUP_TIMESTAMP;
global.VIEWER_URL_IDENTIFIER = "uid=" + global.VIEWER_UNIQUE_ID + "&vv=" + global.VIEWER_VERSION;

global.SUBSCRIPTION_STATUS = {
  ON_TRIAL: "On Trial",
  TRIAL_EXPIRED: "Trial Expired",
  SUBSCRIBED: "Subscribed",
  SUSPENDED: "Suspended",
  CANCELLED: "Cancelled",
  FREE: "Free",
  NOT_SUBSCRIBED: "Not Subscribed",
  PRODUCT_NOT_FOUND: "Product Not Found",
  COMPANY_NOT_FOUND: "Company Not Found",
  ERROR: "Error"
};

global.EMBEDDED_PRESENTATIONS_PC = "d3a418f1a3acaed42cf452fefb1eaed198a1c620";
global.PLAYER_PRO_PC = "c4b368be86245bf9501baaa6e0b00df9719869fd";


module.exports = global;
