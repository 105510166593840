const Logger = require("../Logger");
const Utils = require("../Utils");
const ConfigurationFactory = require("../ConfigurationFactory");
const PlaylistItemInfo = require("../Info/PlaylistItemInfo");
const PresentationParser = require("../Parser/PresentationParser");
const ViewerDataInfo = require("../Data/ViewerDataInfo");
const TimelineParser = require("../Parser/TimelineParser");
const TimerController = require("./TimerController");
const PlaylistItemController = require("./PlaylistItemController");
const WidgetController = require("./WidgetController");
const GadgetController = require("./GadgetController");
const PlaceholderVideoController = require("./PlaceholderVideoController");
const VideoController = require("./VideoController");

function PlaceholderController(placeholder, presFrame, htmlName,
  onPlaceholderReady, onPlaceholderDone) {
  const factory = {};

  const GADGET_WRAPPER_SCRIPT = `<script>try {
      updateGadgetWrapper('%s1', '%s2', %s3, %s4, '%s5');
      } catch(err) { parent.writeToLog('updateGadgetWrapper call - %s2 - ' + err.message); }
      </script>`;

  let status;

  const gadgets = [];

  let videoController;

  let currentItem = -1, nextItem = -1, lastItem = -1;
  let doneReceived = false;

  const timerController = TimerController.getInstance();
  let itemTime = -1;
  let scheduleTime = -1;

  let isPlaying = false;

  factory.init = () => {
    status = PlaylistItemInfo.LOADED_STATUS;

    _bindPlaylist();
  };

  let _bindPlaylist = () => {
    // Removes items with blank durations
    let count = 0;

    if (placeholder && placeholder.items) {
      for (let i = 0; i < placeholder.items.length; i++) {
        const item = placeholder.items[i];

        if (!(item.duration < 1) && (item.distributeToAll || ConfigurationFactory.checkDistribution(item.distribution))) {
          let gadgetName = htmlName + "_" + count;

          // add first letter of Gadget type to the name
          if (item.type && item.type.length > 1) {
            gadgetName = gadgetName + item.type.substring(0, 1);
          }

          let gadget;
          if (item && item.type === PlaylistItemInfo.TYPE_VIDEO) {
            if (videoController == null) {
              videoController = new PlaceholderVideoController(placeholder, presFrame, htmlName);
            }
            gadget = new VideoController(item, gadgetName, _onGadgetReady, _onGadgetDone, videoController);
          }
          else if (item && item.type === PlaylistItemInfo.TYPE_GADGET) {
            gadget = new GadgetController(placeholder, item, htmlName, gadgetName, _onGadgetReady, _onGadgetDone);
          }
          else if (item && item.type === PlaylistItemInfo.TYPE_WIDGET) {
            gadget = new WidgetController(placeholder, item, htmlName, gadgetName, _onGadgetReady, _onGadgetDone);
          }
          else {
            gadget = new PlaylistItemController(placeholder, item, htmlName, gadgetName, _onGadgetReady, _onGadgetDone);
          }

          if (ConfigurationFactory.isDisplay() && item) {
            if (item.type === PlaylistItemInfo.TYPE_GADGET) {
              Logger.logExternalMessage("gadget used", item.objectReference);
              Logger.recordUptimeHeartbeat({
                eventApp: "Gadget: " + gadgetName + " ID: " + item.objectReference,
                eventAppVersion: "N/A" // version should not be empty
              });
            } else if (item.type === PlaylistItemInfo.TYPE_WIDGET) {
              Logger.logExternalMessage("widget used", item.objectReference);
              Logger.recordUptimeHeartbeat({
                eventApp: "Widget: " + gadgetName + " ID: " + item.objectReference,
                eventAppVersion: "N/A" // version should not be empty
              });
            } else {
              Logger.logExternalMessage(item.type + " item used", "");
              Logger.recordUptimeHeartbeat({
                eventApp: "Item: " + gadgetName + " ID: " + item.objectReference,
                eventAppVersion: "N/A" // version should not be empty
              });
            }
         }

          gadgets.push(gadget);
          count++;
        } else {
          WidgetController.sendReadyToPlayer(item.objectData);
        }
      }
    }

    if (gadgets.length === 0) {
      _onGadgetReady();
    }
  };

  factory.updateHtml = (presentation) => {
    if (gadgets.length !== 0) {
      // load placeholder as hidden (show only if empty)
      PresentationParser.hidePlaceholder(presentation, placeholder.id);

      _addGadgetWrapper(presentation, placeholder.id, htmlName,
          0, 0,
          placeholder.transition);

      for (let i = 0; i < gadgets.length; i++) {
        gadgets[i].updateHtml(presentation);
      }

      if (videoController) {
        videoController.updateHtml(presentation);
      }
    } else if (placeholder.visibility === false) {
      // hide empty non visible placeholders
      PresentationParser.hidePlaceholder(presentation, placeholder.id);
    }
  };

  let _addGadgetWrapper = (presentation, containerName, htmlName, width, height, transition) => {
    const modifiedGadgetScript = GADGET_WRAPPER_SCRIPT.replace(/%s1/g, containerName)
            .replace(/%s2/g, htmlName)
            .replace(/%s3/g, width)
            .replace(/%s4/g, height)
            .replace(/%s5/g, transition);

    PresentationParser.addScriptTag(presentation, modifiedGadgetScript);
  };

  let _onGadgetReady = () => {
    if (status !== PlaylistItemInfo.READY_STATUS) {
      status = PlaylistItemInfo.READY_STATUS;
      onPlaceholderReady();
    }

    // if no Gadget was selected as the Current item, but the playlist is supposed to play
    // play the ready gadget
  //		if (isPlaying && currentItem == -1) {
  //			for (int i = 0; i < gadgets.length; i++) {
  //				if (gadgets.get(i).isReady() && TimelineParser.canPlay(gadgets.get(i).getItem())) {
  //					currentItem = i;
  //
  //					if (!gadgets[currentItem].getItem().playUntilDone) {
  //						int duration = RiseUtils.strToInt(gadgets[currentItem].getItem().getDuration(), 0);
  //						// Schedule the timer to run once in x seconds.
  //						setNextItemCheck(duration);
  // //						itemTimer.schedule(duration * 1000);
  //					}
  //					else {
  //						// cancel item check if PUD
  //						setNextItemCheck(-1);
  //					}
  //
  //					break;
  //				}
  //			}
  //		}

    // if lastItem == -1 then we haven't played through any
    if (isPlaying && lastItem === -1 && gadgets.length > 0) {
      if (currentItem !== -1 && gadgets[currentItem].isReady()) {
        _showPlaceholder(true);

        gadgets[currentItem].play(true);
        lastItem = currentItem;
      } else if (currentItem === -1) {
        _playNextItem(true);
      }
    }
  };

  let _onGadgetDone = () => {
    if (doneReceived) {
      Logger.logDebug(`Placeholder ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Done received multiple times!`);

      gadgets[currentItem].stop(true);

      // Will trigger next item playback
      currentItem = -1;
    } else {
      doneReceived = true;
    }

    if (isPlaying && currentItem !== -1 && !gadgets[currentItem].getItem().playUntilDone &&
      lastItem === currentItem) {
      gadgets[currentItem].stop(false);
      gadgets[currentItem].play(false);
    }
    else {
      // Next item should play - reset doneReceived
      doneReceived = false;

      _playNextItem(true);
    }

    // Reset doneReceived once play has executed
    // to prevent recursion loop
    doneReceived = false;
  };

  const _timerExecute = () => {
    if (itemTime !== -1) {
      itemTime--;
    }

    // calculate the next item time
    if (itemTime === 0) {
      _playNextItem(true);
    }

    if (scheduleTime !== -1) {
      scheduleTime--;
    }

    if (scheduleTime === 0) {
      if (gadgets.length === 0) {
        _playEmptyPlaceholder();
      } else {
        _verifySchedule(true);
      }
    }
  };

  const _setNextItemCheck = (seconds) => {
    itemTime = seconds;
  };

  const _setNextScheduleCheck = (seconds) => {
    scheduleTime = seconds;
  };

  factory.play = () => {
    Logger.setEndpointLoggerContentFields({placeholderId: placeholder.id});
    if (placeholder.visibility !== false && !isPlaying) {
      if (gadgets.length === 0 && TimelineParser.applies()) {
        _playEmptyPlaceholder();
      } else if (/* status == READY_STATUS && */ gadgets.length > 0) {
        if (TimelineParser.applies()) {
          _verifySchedule(false);
        } else {
          isPlaying = true;
          _playNextItem(false);
        }
      }
    }

    // returning false calls presentationDone
    return !onPlaceholderDone || gadgets.length === 0 || isPlaying ||
      placeholder.visibility === false;
  };

  let _playEmptyPlaceholder = () => {
    if (TimelineParser.canPlay(placeholder)) {
      if (!isPlaying) {
        isPlaying = true;
        _showPlaceholder(true);
      }
    } else {
      if (isPlaying) {
        factory.stop();
      }
      _showPlaceholder(false);
    }

    _setNextScheduleCheck(60 - new Date().getSeconds());
  };

  let _verifySchedule = (executeDoneCommand) => {
    _setNextScheduleCheck(60 - new Date().getSeconds());

    if (TimelineParser.canPlay(placeholder)) {
      if (!isPlaying) {
        isPlaying = true;
        _playNextItem(executeDoneCommand);
      } else if (currentItem !== -1 && !TimelineParser.canPlay(gadgets[currentItem].getItem())) {
        _playNextItem(executeDoneCommand);
      }
    } else if (onPlaceholderDone && executeDoneCommand) {
      onPlaceholderDone();
    } else {
      if (isPlaying) {
        factory.stop();
      }
      _showPlaceholder(false);
    }
  };

  let _playNextItem = (executeDoneCommand) => {
    // Reset any previous timers
    _setNextItemCheck(-1);

    // only send Done if an item actually played
    if (onPlaceholderDone && nextItem === gadgets.length - 1) {
      nextItem = -1;
      isPlaying = false;

      if (executeDoneCommand && lastItem !== -1) {
        Logger.logDebug(`Placeholder ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- executeDoneCommand!`);

        onPlaceholderDone();
      } else if (lastItem === -1 && currentItem !== -1) {
        gadgets[currentItem].stop(true);

        // we haven't played through any items yet
        currentItem = -1;
      }

      return;
    }

    // signifies Done was sent right after Play
    if (isPlaying && lastItem !== currentItem && currentItem !== -1 && gadgets[currentItem].isReady()) {
      Logger.logDebug(`Placeholder ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Done after play Stop!`);

      gadgets[currentItem].stop(true);

      // we haven't played through any items yet
      if (lastItem === -1) {
        currentItem = -1;
      } else if (nextItem === gadgets.length - 1) {
        // Stop the last item just in case before clearing reference
        gadgets[lastItem].stop(true);

        lastItem = -1;
      }
    }

    _setNextItem();

    if (nextItem === -1) {
      Logger.logDebug(`Placeholder ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- end of playback stop!`);

      // This will stop playback; stop the last item just in case
      if (lastItem !== -1) {
        gadgets[lastItem].stop(true);
        lastItem = -1;
      }

      _setNextItemCheck(60 - new Date().getSeconds());

      _showPlaceholder(false);
    } else if (!TimelineParser.canPlay(gadgets[nextItem].getItem())) {
      if (nextItem === currentItem) {
        currentItem = -1;
      }

      _playNextItem(executeDoneCommand);
    } else if (!gadgets[nextItem].isReady() && gadgets[nextItem].getItem().playUntilDone) {
      // if the item is PUD but not ready, skip it
      _playNextItem(executeDoneCommand);
    } else if (!gadgets[nextItem].isReady()) {
      if (gadgets.length !== 1) {
        // Continue playing current item for next item duration
        const duration = gadgets[nextItem].getItem().duration;
        _setNextItemCheck(duration);
      }

      currentItem = nextItem;
    } else {
      _showPlaceholder(true);
      currentItem = nextItem;
  //			isPlaying = true;

      if (!gadgets[currentItem].getItem().playUntilDone) {
        const duration = gadgets[currentItem].getItem().duration;
          // Schedule the timer to run once in x seconds.
        _setNextItemCheck(duration);
      }

      Logger.logDebug(`Placeholder ${htmlName} ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Counters before - last:${lastItem} current:${currentItem} next:${nextItem}`);

      gadgets[currentItem].play(true);

      if (lastItem !== -1 && currentItem !== lastItem) {
        gadgets[lastItem].pause(true);
      }

      Logger.logDebug(`Placeholder ${htmlName} ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Counters after - last:${lastItem} current:${currentItem} next:${nextItem}`);

      lastItem = currentItem;
    }
  };

  let _setNextItem = () => {
    if (nextItem < gadgets.length - 1) {
      nextItem++;
    } else if (currentItem !== -1) {
      nextItem = 0;
    } else {
      nextItem = -1;
    }
  };

  factory.stop = () => {
    Logger.logDebug(`Placeholder ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Stop!`);

    isPlaying = false;

    _setNextItemCheck(-1);
    _setNextScheduleCheck(-1);

    if (currentItem !== -1) {
      gadgets[currentItem].stop(true);

      // [AD] Prevent done next time the placeholder is played
      if (nextItem === gadgets.length - 1) {
        nextItem = -1;
      }
    }
  };

  factory.pause = () => {
    Logger.logDebug(`Placeholder ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Pause!`);

    isPlaying = false;

    _setNextItemCheck(-1);
    _setNextScheduleCheck(-1);

    if (currentItem !== -1) {
      // AD: Added hide = true so pause can be called between presentations switching
      gadgets[currentItem].pause(true);

      // [AD] Prevent done next time the placeholder is played
      if (nextItem === gadgets.length - 1) {
        nextItem = -1;
      }
    }
  };

  factory.getStatus = () => {
    return status;
  };

  let _showPlaceholder = (show) => {
    Utils.showFrameElement(presFrame, placeholder.id, show);
  };

  factory.unload = () => {
    for (let i = 0; i < gadgets.length; i++) {
      gadgets[i].setReady(false);
    }

    status = PlaylistItemInfo.LOADED_STATUS;
  };

  (function () {
    timerController.addTimerCommand(_timerExecute);
  }());

  return factory;
}

PlaceholderController.sendReadyToPlayerAllItems = (placeholder) => {
  placeholder.items.forEach((item) => {
    if (item.type === PlaylistItemInfo.TYPE_WIDGET) {
      WidgetController.sendReadyToPlayer(item.objectData);
    } else if (item.type === PlaylistItemInfo.TYPE_PRESENTATION) {
      const presentation = ViewerDataInfo.getPresentation(item.objectData);
      PresentationParser.parsePresentation(presentation);
      presentation.placeholders.forEach(PlaceholderController.sendReadyToPlayerAllItems);
    }
  });
};

module.exports = PlaceholderController;
