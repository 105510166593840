const Config = require("../Config/Config");
const Global = require("../Config/Global");
const Logger = require("../Logger");

const STATUS = Global.SUBSCRIPTION_STATUS;
const SUBSCRIBED_STATUS = [STATUS.ON_TRIAL, STATUS.SUBSCRIBED, STATUS.FREE];
const PATH_URL = "v1/company/companyId/product/status?pc=";
const AUTH_PATH_URL = "v1/widget/auth?startTrial=false&cid=companyId&pc=";
const AUTH_DISPLAY_PATH_URL = "v1/widget/auth?startTrial=false&id=displayId&pc=";
const AUTHORIZATION_STATUS_NAME = "authorizationStatus";

const PING_TIME = 1 * Global.MINUTE_UPDATE_INTERVAL;
const MAX_PING_ATTEMPTS = 3;
let pingInterval;

const cachedStatus = {};
const cachedAuth = {};
const service = {};

const subscriptionStatusHandlers = {};
const authorizationHandlers = {};

const _supportsLocalStorage = () => {
  try {
    return "localStorage" in window && window.localStorage !== null;
  } catch (e) {
    return false;
  }
};

const _loadFromLocalStorage = (cacheKey) => {
  if (_supportsLocalStorage()) {
    return JSON.parse(localStorage.getItem(cacheKey));
  }

  return null;
};

const _saveToLocalStorage = (cacheKey, response) => {
  if (response && _supportsLocalStorage()) {
    try {
      localStorage.setItem(cacheKey, JSON.stringify(response));
    } catch (e) {
      Logger.log("Error Caching authorization status - " + e.message);
    }
  }
};

const _addHandler = (id, productCode, handlers, handler) => {
  const cacheKey = id + productCode;
  const isLoading = handlers[cacheKey] && handlers[cacheKey].loading;

  if (!handlers[cacheKey]) {
    handlers[cacheKey] = {
      loading: true,
      handlers: []
    };
  }

  handlers[cacheKey].handlers.push(handler);
  return isLoading;
};

const _processHandlers = (id, productCode, handlers, cb) => {
  const cacheKey = id + productCode;

  if (handlers[cacheKey]) {
    handlers[cacheKey].handlers.forEach((handler) => {
      cb(handler);
    });
  }
};

const _clearHandlers = (id, productCode, handlers, handler) => {
  const cacheKey = id + productCode;

  if (handlers[cacheKey]) {
    delete handlers[cacheKey];
  }
};

const _getSubscriptionStatus = (companyId, productCode, cb) => {
  const url = Config.STORE_SERVER_URL +
      PATH_URL.replace("companyId", companyId) +
      productCode;

  if (_addHandler(companyId, productCode, subscriptionStatusHandlers, cb)) {
    Logger.log(`Added listener for subscription status - CompanyId: ${companyId} - Product ${productCode}`);
    return;
  }
  else {
    Logger.log(`Loading subscription status - CompanyId: ${companyId} - Product ${productCode} - Url: ${url}`);
  }

  jQuery.getJSON(url, (response) => {
    const resp = _processSubscriptionStatus(response);
    Logger.log("Subscription status - " + resp.status);

    _processHandlers(companyId, productCode, subscriptionStatusHandlers, (handler) => {
      handler(resp.status, resp.subscribed);
    });
  })
  .fail((jqXHR, textStatus, error) => {
    Logger.logException("SubscriptionStatusProvider._getSubscriptionStatus.fail", error, url, textStatus);

    _processHandlers(companyId, productCode, subscriptionStatusHandlers, (handler) => {
      handler(STATUS.ERROR, false);
    });
  })
  .always(() => {
    _clearHandlers(companyId, productCode, subscriptionStatusHandlers);
  });
};

const _processSubscriptionStatus = (response) => {
  const resp = { subscribed: false };

  if (response && response.length > 0) {
    const status = response[0].status;
    const exists = Object.keys(STATUS).map((k) => { return STATUS[k]; }).indexOf(status) >= 0;

    if (exists) {
      resp.status = status;

      if (SUBSCRIBED_STATUS.indexOf(status) >= 0) {
        resp.subscribed = true;
      }
    }
    else {
      Logger.log("Subscription status - Invalid status", response[0].status);
      resp.status = STATUS.ERROR;
    }
  }
  else {
    Logger.log("Subscription status - Empty response");
    resp.status = STATUS.ERROR;
  }

  return resp;
};

const _getAuthorizedStatusAttempt = (url, pingAttempt, cb) => {
  if (pingAttempt > MAX_PING_ATTEMPTS) {
    cb();

    clearTimeout(pingInterval);
    return;
  }

  pingInterval = setTimeout(() => {
    clearTimeout(pingInterval);
    _getAuthorizedStatusAttempt(url, pingAttempt + 1, cb);
  }, PING_TIME);

  jQuery.getJSON(url, (response) => {
    clearTimeout(pingInterval);
    if (!(response && response.status === 202)) {
      cb(response);
    } else {
      _getAuthorizedStatusAttempt(url, 1, cb);
    }
  })
  .fail((jqXHR, textStatus, error) => {
    Logger.logException("SubscriptionStatusProvider._getAuthorizedStatusAttempt.fail - " + pingAttempt,
      error, url, textStatus);
  });

  if (pingAttempt > 1) {
    Logger.logExternalMessage("Get Authorized Status attempt", pingAttempt);
    Logger.viewerDebug("Get Authorized Status attempt", pingAttempt);
  }
};

const _getAuthorizedStatusForUrl = (id, productCode, cb, cacheKey, url) => {
  if (_addHandler(id, productCode, authorizationHandlers, cb)) {
    Logger.log(`Added listener for authorization status - Id: ${id} - Product ${productCode}`);
    return;
  }
  else {
    Logger.log(`Loading authorization status - Id: ${id} - Product ${productCode} - Url: ${url}`);
  }

  _getAuthorizedStatusAttempt(url, 1, (response) => {
    let authorized = false;

    if (response) {
      Logger.log("Authorized status reponse - " + response.authorized);

      _saveToLocalStorage(cacheKey, response);

      authorized = response.authorized;
    } else {
      response = _loadFromLocalStorage(cacheKey);

      if (response) {
        Logger.logExternalMessage("Using Cached Authorized status", response.authorized);
        Logger.viewerDebug("Using Cached Authorized status", response.authorized);

        authorized = response.authorized;
      } else {
        Logger.logExternalMessage("Using Authorized status default", false);
        Logger.viewerDebug("Using Authorized status default");
      }
    }

    _processHandlers(id, productCode, authorizationHandlers, (handler) => {
      handler(authorized);
    });
    _clearHandlers(id, productCode, authorizationHandlers);
  });
};

const _getAuthorizedStatus = (companyId, productCode, cb) => {
  const cacheKey = AUTHORIZATION_STATUS_NAME + companyId + productCode;
  const storeUrl = Config.STORE_SERVER_URL +
      AUTH_PATH_URL.replace("companyId", companyId) +
      productCode;

  if (RiseVision.Viewer.ConfigurationFactory.isDisplay()) {
    RiseVision.Viewer.Cache.RiseCacheController.getCacheUrl(storeUrl, (url) => {
      return _getAuthorizedStatusForUrl(companyId, productCode, cb, cacheKey, url);
    });
  }
  else {
    return _getAuthorizedStatusForUrl(companyId, productCode, cb, cacheKey, storeUrl);
  }
};

const _getDisplayAuthorizedStatus = (displayId, productCode, cb) => {
  const cacheKey = AUTHORIZATION_STATUS_NAME + displayId + productCode;
  const storeUrl = Config.STORE_SERVER_URL +
      AUTH_DISPLAY_PATH_URL.replace("displayId", displayId) +
      productCode;

  if (RiseVision.Viewer.ConfigurationFactory.isDisplay()) {
    RiseVision.Viewer.Cache.RiseCacheController.getCacheUrl(storeUrl, (url) => {
      return _getAuthorizedStatusForUrl(displayId, productCode, cb, cacheKey, url);
    });
  }
  else {
    return _getAuthorizedStatusForUrl(displayId, productCode, cb, cacheKey, storeUrl);
  }
};

service.loadStatus = (companyId, productCode, cb) => {
  const cacheKey = companyId + productCode;

  if (cachedStatus[cacheKey] && (typeof cachedAuth[cacheKey] !== "undefined")) {
    Logger.log("Cached subscription status - " + cachedStatus[cacheKey] + " | " + cachedAuth[cacheKey]);
    cb(cachedStatus[cacheKey], cachedAuth[cacheKey]);
  }
  else {
    _getSubscriptionStatus(companyId, productCode, (status, subscribed) => {
      cachedStatus[cacheKey] = status;

      if (!subscribed && status !== STATUS.ERROR) {
        _getAuthorizedStatus(companyId, productCode, (authorized) => {
          cachedAuth[cacheKey] = authorized;
          cb(status, authorized);
        });
      }
      else {
        cachedAuth[cacheKey] = subscribed;
        cb(status, subscribed);
      }
    });
  }
};

service.checkAuthorizationStatus = (companyId, productCode, cb) => {
  const cacheKey = companyId + productCode;

  if (typeof cachedAuth[cacheKey] !== "undefined") {
    Logger.log("Cached authorization - " + cachedAuth[cacheKey]);
    cb(cachedAuth[cacheKey]);
  } else {
    _getAuthorizedStatus(companyId, productCode, (authorized) => {
      cachedAuth[cacheKey] = authorized;
      cb(authorized);
    });
  }
};

service.checkDisplayAuthorizationStatus = (displayId, productCode, cb) => {
  const cacheKey = displayId + productCode;

  if (typeof cachedAuth[cacheKey] !== "undefined") {
    Logger.log("Cached authorization - " + cachedAuth[cacheKey]);
    cb(cachedAuth[cacheKey]);
  } else {
    _getDisplayAuthorizedStatus(displayId, productCode, (authorized) => {
      cachedAuth[cacheKey] = authorized;
      cb(authorized);
    });
  }
};

module.exports = service;
