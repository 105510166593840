"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.streamHeartbeatTableEntry = exports.streamEventsTableEntry = void 0;

var _config = require("./config.js");

var _token = require("./token.js");

var _retryFn = require("./retry-fn.js");

var streamTableEntry = function streamTableEntry() {
  var fields = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var url = arguments.length > 1 ? arguments[1] : undefined;
  var insertData = JSON.parse(JSON.stringify(_config.INSERT_SCHEMA));
  fields.timestamp = "AUTO";
  insertData.rows[0].json = fields;
  var body = JSON.stringify(insertData);
  return (0, _token.getToken)().then(function (token) {
    return (0, _retryFn.retry)(function () {
      return fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer ".concat(token)
        },
        body: body
      });
    });
  }).then(function (resp) {
    return resp.json();
  }).then(function (json) {
    return json.error ? Promise.reject(Error(json.error.message)) : json.insertErrors && json.insertErrors[0] ? Promise.reject(Error(JSON.stringify(json.insertErrors[0].errors[0]))) : json;
  })["catch"](console.error);
};

var streamEventsTableEntry = function streamEventsTableEntry(fields) {
  return streamTableEntry(fields, _config.EVENTS_SERVICE_URL);
};

exports.streamEventsTableEntry = streamEventsTableEntry;

var streamHeartbeatTableEntry = function streamHeartbeatTableEntry(fields) {
  return streamTableEntry(fields, _config.HEARTBEAT_SERVICE_URL);
};

exports.streamHeartbeatTableEntry = streamHeartbeatTableEntry;