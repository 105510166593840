"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reset = exports.getLogLevel = exports.setEndpointId = void 0;

var _retryFn = require("./retry-fn.js");

var refreshDate = localStorage.getItem("bq-endpoint-logger-fetchdate");
var levelCache = localStorage.getItem("bq-endpoint-logger-level");
var serviceUrl = null;
var GCS_SERVICE_URL = "https://storage.googleapis.com/storage/v1/b/risevision-endpoint-loglevels/o/ENDPOINT_ID.json?alt=media";
var THROTTLE_MAX_5_MIN = 300000;

var recentlyFetched = function recentlyFetched() {
  return new Date() - (refreshDate || 0) < THROTTLE_MAX_5_MIN;
};

var setEndpointId = function setEndpointId(id) {
  serviceUrl = GCS_SERVICE_URL.replace("ENDPOINT_ID", id);
};

exports.setEndpointId = setEndpointId;
var levelPromise = null;

var getLogLevel = function getLogLevel() {
  if (levelPromise) {
    return levelPromise;
  }

  if (levelCache && recentlyFetched()) {
    return Promise.resolve(levelCache);
  }

  return levelPromise = levelPromise || (0, _retryFn.retry)(function () {
    return fetch(serviceUrl);
  }).then(function (resp) {
    refreshDate = new Date();
    localStorage.setItem("bq-endpoint-logger-fetchdate", refreshDate);

    if (resp.status === 404) {
      return {
        logLevel: "ERROR"
      };
    }

    return resp.json();
  }).then(function (json) {
    var level = json.logLevel || "ERROR";
    localStorage.setItem("bq-endpoint-logger-level", level);
    return level;
  })["catch"](function (err) {
    console.error(err);
    return levelCache || "ERROR";
  });
};

exports.getLogLevel = getLogLevel;

var reset = function reset() {
  levelPromise = null;
  levelCache = null;
};

exports.reset = reset;