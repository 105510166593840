const ConfigurationFactory = require("../ConfigurationFactory");
const Logger = require("../Logger");
const ExtLogger = require("../ExtLogger");
const Utils = require("../Utils");
const Global = require("../Config/Global");
const ViewerDataInfo = require("./ViewerDataInfo");
const GCSController = require("./GCSController");
const Messaging = require("../Messaging");
const ViewerNotificationsPanel = require("../UI/ViewerNotificationsPanel");
const NotificationType = require("../Info/NotificationType");
const queryString = require("query-string");
const service = {};

const IDLE_STATE = 0;
const ACTIVE_STATE = 1;
const WAITING_STATE = 2;
const CONTENT_STATE = 3;

const DATA_SOURCE_PARAM = "dataSource=";
const DATA_SOURCE_CORE = "core";
// Unused, added for completeness
// const DATA_SOURCE_GCS = "gcs";

let state = IDLE_STATE;
let apiDelay = 1;

service.REASON = {
  RETRIEVAL_TIMEOUT: "RETRIEVAL_TIMEOUT",
  UPDATE_MESSAGE_RECEIVED: "UPDATE_MESSAGE_RECEIVED",
  VIEWER_INIT: "VIEWER_INIT",
  POLLING_TIMER: "POLLING_TIMER",
  PREVIEW: "PREVIEW"
};

const _reportDataReady = function (result) {
  state = ACTIVE_STATE;
  apiDelay = 1;

  window.RiseVision.Viewer.Data.ViewerDataController.reportDataReady(result);
};

const _isDataSourceCore = () => {
  const dataSource = Utils.getFromQueryString(DATA_SOURCE_PARAM);

  if (!ConfigurationFactory.isDisplay() && !ConfigurationFactory.isSharedschedule()) {
    return true;
  } else if (dataSource === DATA_SOURCE_CORE) {
    return true;
  } else {
    return false;
  }
};

const _getData = function (url) {
  if (!jQuery || !jQuery.getJSON) { return; }

  if (_isDataSourceCore()) {
    return _getFromCore(url);
  }

  Logger.log("Retrieving Viewer Data");

  GCSController.getContent((result) => {
    if (!result || !result.content) {
      return _getFromCore(url);
    }

    _reportDataReady(result);
  });
};

const _getFromCore = function (url) {
  jQuery.getJSON(url, (result) => {
    try {
      Logger.log("Viewer Data - Status Message - " + result.status.message);

      _reportDataReady(result);
    }
    catch (err) {
      Logger.logException("ViewerDataProvider._getData", err, url, result);
    }
  })
  .fail((jqXHR, textStatus, error) => {
    Logger.logException("ViewerDataProvider._getData.fail", error, url, textStatus);
  });
};

const _dataTimerExecute = function () {
  if (state === WAITING_STATE) {
    if (!ViewerDataInfo.hasData()) {
      ViewerNotificationsPanel.showError(NotificationType.SERVER_CONNECTION_FAILED, false, apiDelay * 60);
    }

    state = CONTENT_STATE;
  }

  // signifies last attempt to call the Viewer API failed, so we will inform the user of it.
  if (state === CONTENT_STATE) {
    state = IDLE_STATE;

    service.retrieveData(service.REASON.RETRIEVAL_TIMEOUT + ` API Delay: ${apiDelay} min`);
  }
  else {
    state = IDLE_STATE;
  }
};

const _getPlayerConfigData = function () {
  const sysInfo = ConfigurationFactory.getSysInfo() || "";
  const browserInfo = Utils.getBrowserVersion();
  const playerConfig = {};

  try {
    const info = queryString.parse(decodeURIComponent(sysInfo));

    playerConfig.os_description = info.os || "";
    playerConfig.installer_version = info.iv || "";
    playerConfig.player_name = info.pn || "";
    playerConfig.java_version = info.jv || "";
    playerConfig.player_version = info.pv || "";
    playerConfig.cache_version = info.ev || "";
  } catch (e) {
    Logger.log("Player Data - No system info");
  }

  playerConfig.viewer_version = Global.VIEWER_VERSION;
  playerConfig.browser_name = browserInfo[0];
  playerConfig.browser_version = browserInfo[1];
  playerConfig.width = window.innerWidth;
  playerConfig.height = window.innerHeight;

  return playerConfig;
};

const _logConfigData = function (apiUrl, cb) {
  const playerConfigData = _getPlayerConfigData();
  let storedConfigData = null;

  try {
    storedConfigData = JSON.parse(localStorage.getItem("playerConfig"));
  } catch (e) {
    Logger.log("No player config data in local storage");
  }

  if (!storedConfigData || JSON.stringify(playerConfigData) !== JSON.stringify(storedConfigData)) {
    Logger.logPlayerConfigData(playerConfigData, (insertSuccess) => {
      if (insertSuccess) {
        try {
          localStorage.setItem("playerConfig", JSON.stringify(playerConfigData));
        } catch (e) {
          Logger.logExternalMessage("Player config data cache set fail", e.message);
          Logger.viewerWarning("Player config data cache set fail", e.message);
        }
      }
      cb(apiUrl);
    });
  } else {
    Logger.log("Player config data has not changed");
    cb(apiUrl);
  }
};

service.retrieveData = function (reason) {
  if (window.disableViewerContentFetch) { return; }

  if (state === IDLE_STATE) {
    let url;

    url = Global.DATA_SERVER_URL.replace("{0}", ConfigurationFactory.getType());
    url = url.replace("{1}", ConfigurationFactory.getId());

    const sysInfo = ConfigurationFactory.getSysInfo() || "";
    const browserInfo = Utils.getBrowserVersion();

    const fullUrl = url +
        "?sig=" + ViewerDataInfo.getSig() +
        "&" + Global.VIEWER_URL_IDENTIFIER +
        (sysInfo ? "&" + decodeURIComponent(sysInfo) : "") +
        "&cn=" + browserInfo[0] +
        "&cv=" + browserInfo[1] +
        "&width=" + window.innerWidth +
        "&height=" + window.innerHeight;

    state = WAITING_STATE;

    // start 60 second timer for timeout of data retrieval
    setTimeout(_dataTimerExecute, apiDelay * Global.MINUTE_UPDATE_INTERVAL);

    apiDelay = Math.min(apiDelay * 2, ViewerDataInfo.getPollInterval());

    _logConfigData(fullUrl, _getData);
  }
  else {
    state = CONTENT_STATE;
  }
};

module.exports = service;
