const ConfigurationFactory = require("../ConfigurationFactory");
const Utils = require("../Utils");
const PlaylistItemInfo = require("../Info/PlaylistItemInfo");
const PlaylistItemUtils = require("./PlaylistItemUtils");
const PresentationParser = require("../Parser/PresentationParser");
const SubscriptionStatusProvider = require("../Data/SubscriptionStatusProvider");
const Global = require("../Config/Global");
const Logger = require("../Logger");

const PlaylistItemController = function (placeholderInfo, playlistItem, phName, htmlName, onGadgetReady, onGadgetDone) {
  const factory = {};

  let presFrame;
  let isReady = false, isError = false, isPlaying = false;

  const PRESENTATION_SUBSCRIPTION_ERROR = "Embedded Presentations subscription is not active.";
  const SUBSCRIPTION_ERROR_HTML = `<div style='position: absolute; top: 0; left: 0; width: 100%; height:100%;'>
      <p style='text-shadow: 1px 1px 1px rgba(0, 0, 0, .3); position: relative; padding: 14px; background-color: rgba(80, 80, 80, 0.8); top: 50%; transform: translateY(-50%); text-align: center; margin-right: 15px; color: white; font-family: sans-serif;'>%s1</p>
      </div>`;

  let subscriptionError, subscriptionResponseCount = 0;

  const EMBED_SCRIPT = "<script><!--\n" +
			"try {" +
			"updateEmbed('%s1', '%s2', '%s3', '%s4');" +
			"} catch(err) { parent.writeToLog('updateEmbed call - %s3 - ' + err.message); }" +
			"\n//-->" +
			"</script>";

  const TEXT_SCRIPT = "<script><!--\n" +
			"try {" +
			"updateText('%s1', '%s2', '%s3', '%s4');" +
			"} catch(err) { parent.writeToLog('updateText call - %s3 - ' + err.message); }" +
			"\n//-->" +
			"</script>";

  const IMAGE_SCRIPT = "<script><!--\n" +
			"try {" +
			"updateImage('%s1', '%s2', '%s3', '%s4');" +
			"} catch(err) { parent.writeToLog('updateImage call - %s2 - ' + err.message); }" +
			"\n//-->" +
			"</script>";

  const IMAGE_HTML = "" +
			"<html>" +
			"<head>" +
			"<meta http-equiv=\"content-type\" content=\"text/html; charset=UTF-8\">" +
			"<title></title>" +
			"<script type=\"text/javascript\" src=\"./scripts/imageScripts.js\"></script>" +
			"<style>" +
			"	body { background-color: transparent; -moz-user-select: none; -webkit-user-select: none; -khtml-user-select: none; user-select: none; }" +
			"	#cnt {width:100%; height:100%; background-color: %s4%;}" +
			"	#wrapper {width:100%; height:100%; display:table;}" +
			"	#cell {display:table-cell; vertical-align:middle;}" +
			"	#image {display: block; margin: auto;}" +
			"</style>" +
			"<script type='text/javascript'>" +
			"var id = '%s0%';" +
			"(function() {" +
			"	window.onresize = function(event) {" +
			"		resizeImage();" +
			"	};" +
			"	window.oncontextmenu = function() {" +
			"		return false;" +
			"	};" +
			"}());" +
			"function resizeImage() {" +
			"	if (%s5%) {" +
			"		var width = Math.max(document.body.clientWidth, document.documentElement.clientWidth);" +
			"		var height = Math.max(document.body.clientHeight, document.documentElement.clientHeight);" +
			"		var img = document.getElementById('image');" +
			"		if(img.width/img.height < width/height) {" +
			"			img.style.width = 'auto';" +
			"			img.style.height = height;" +
			"		} else {" +
			"			img.style.width = '100%';" +
			"			img.style.height = 'auto';" +
			"		}" +
			"	}" +
			"}" +
			"function readyEvent() {" +
			"	var img = document.getElementById('image');" +
//			"	img.onload = function() {" +
//			"		resizeImage();" +
//			"		readyEvent();" +
//			"	};" +
			"	img.onclick = function() {" +
			"		try {" +
			"			parent.onClick(id);" +
			"		}" +
			"		catch (e) {}" +
			"	};" +
			"	img.draggable = false;" +
			"" +
			"	parent.itemReady(id, true, true, true, true, true);" +
			"}" +
			"</script>" +
			"</head>" +
			"<body style=\"margin:0px;\">" +
			"<div id=\"cnt\">" +
			"<div id=\"wrapper\">" +
			"<div id=\"cell\">" +
			"<img id=\"image\" src=\"%s3%\" onload=\"resizeImage(); readyEvent();\" " + /* onclick=\"parent.onClick(id); */ "\">" +
			"</div>" +
			"</div>" +
			"</div>" +
			"</body>" +
			"</html>";

  const URL_SCRIPT = "<script><!--\n" +
			"try {" +
			"updateUrl('%s1', '%s2', '%s3', '%s4');" +
			"} catch(err) { parent.writeToLog('updateUrl call - %s3 - ' + err.message); }" +
			"\n//-->" +
			"</script>";

  factory.updateHtml = (presentation) => {
    if (playlistItem && PlaylistItemInfo.TYPE_PRESENTATION === playlistItem.type) {
      isError = true;

      _updateHtmlEmbed(presentation);

      _checkSubscriptionStatus();
    }
    else if (playlistItem && PlaylistItemInfo.TYPE_TEXT === playlistItem.type
				|| PlaylistItemInfo.TYPE_HTML === playlistItem.type) {
      _updateHtmlText(presentation);
    }
    else if (playlistItem && PlaylistItemInfo.TYPE_VIDEO === playlistItem.type) {

    }
    else if (playlistItem && PlaylistItemInfo.TYPE_IMAGE === playlistItem.type) {
      _updateHtmlImage(presentation);
    }
    else if (playlistItem && PlaylistItemInfo.TYPE_URL === playlistItem.type) {
      _updateHtmlUrl(presentation);
    }
//		else {
//			updateHtmlGadget(presentation);
//		}
  };

  let _updateHtmlEmbed = (presentation) => {
    let transition = "none";
    const embedId = playlistItem.objectData;

    let url = location.protocol + "//" + location.host + "/";

    if (url.indexOf("file") !== -1) {
      url = location.href.split("?")[0];
    }

    if (location.host === "widgets.risevision.com") {
      const appEnv = location.pathname.startsWith("/viewer-stage-") ? "viewer-stage-0" : "viewer";
      url += appEnv + "/";
    }

		// if (!Location.getParameter('gwt.codesvr'))
		// 	url += '?gwt.codesvr=127.0.0.1:9997&';
		// else
    url += "?";
    url += "type=" + ConfigurationFactory.getType() + "&id=" + embedId + "&parentId=" + presentation.id;

    if (ConfigurationFactory.isDisplay()) {
      url += "&displayId=" + ConfigurationFactory.getDisplayId();
    }

    if (ConfigurationFactory.getEnv()) {
      url += "&env=" + ConfigurationFactory.getEnv();
    }

//		int height = 0, width = 0;

//		if (placeholderInfo.getWidthUnits() === '%') {
//			width = (int)((placeholderInfo.getWidth() / 100.0) * Window.getClientWidth());
//			height = (int)((placeholderInfo.getHeight() / 100.0) * Window.getClientHeight());
//		}
//		else {
//			height = (int)placeholderInfo.getHeight();
//			width = (int)placeholderInfo.getWidth();
//		}

//		double scale = ViewerUtils.getItemScale(playlistItem.getScale());

//		height = (int)(height * scale);
//		width = (int)(width * scale);

    transition = placeholderInfo.transition;

    _addEmbedScript(presentation, url, phName, htmlName,
				transition);
  };

  let _addEmbedScript = (presentation, url, containerName, htmlName, transition) => {
    const scriptTag = EMBED_SCRIPT.replace(/%s1/g, url)
						.replace(/%s2/g, containerName)
						.replace(/%s3/g, htmlName)
						.replace(/%s4/g, transition);

    PresentationParser.addScriptTag(presentation, scriptTag);
  };

  let _updateHtmlText = (presentation) => {
    let transition = "none";
    let text = playlistItem.objectData.replace(/\n/g, "").replace(/\r/g, "");

		// Removing comments with regExp
//		var exp = '(?s)<!--.*?-->';
//		text = text.replaceAll('(?s)<!--.*?-->', '');
//		text = text.replaceAll('?s<!--.*?--\\s*>?gs', '');
//		text = text.replaceAll('(?s)<!--.*?--\\s*>?gs', '');
//		var exp = '/<!--[\\s\\S]*?-->/g';

    const exp = "<!--.*?-->";

    text = text.replace(exp, "");

//		int height = 0, width = 0;

//		if (placeholderInfo.getWidthUnits() === '%') {
//			width = (int)((placeholderInfo.getWidth() / 100.0) * Window.getClientWidth());
//			height = (int)((placeholderInfo.getHeight() / 100.0) * Window.getClientHeight());
//		}
//		else {
//			height = (int)placeholderInfo.getHeight();
//			width = (int)placeholderInfo.getWidth();
//		}

//		double scale = ViewerUtils.getItemScale(playlistItem.getScale());

//		height = (int)(height * scale);
//		width = (int)(width * scale);

    transition = placeholderInfo.transition;

    _addTextScript(presentation, text, phName, htmlName,
				transition);
  };

  let _addTextScript = (presentation, text, containerName, htmlName, transition) => {
    text = text.replace(/'/g, "\\'");

    const scriptTag = TEXT_SCRIPT.replace(/%s1/g, text)
					.replace(/%s2/g, containerName)
					.replace(/%s3/g, htmlName)
					.replace(/%s4/g, transition);

    PresentationParser.addScriptTag(presentation, scriptTag);
  };

  let _updateHtmlImage = (presentation) => {
    let transition = "none";

//		int height = 0, width = 0;

//		if (placeholderInfo.getWidthUnits() === '%') {
//			width = (int)((placeholderInfo.getWidth() / 100.0) * Window.getClientWidth());
//			height = (int)((placeholderInfo.getHeight() / 100.0) * Window.getClientHeight());
//		}
//		else {
//			height = placeholderInfo.getHeight();
//			width = placeholderInfo.getWidth();
//		}

//		double scale = ViewerUtils.getItemScale(playlistItem.getScale());

//		height = (int)(height * scale);
//		width = (int)(width * scale);

    const html = _createImageHtmlString(playlistItem.objectData);
    transition = placeholderInfo.transition;

    _addImageScript(presentation, html, phName, htmlName,
				transition);
  };

  let _createImageHtmlString = (objectData) => {
    let htmlString = IMAGE_HTML.replace(/%s0%/g, htmlName);
    htmlString = htmlString.replace(/%s3%/g, Utils.getUrlParam(objectData, "url="));
    htmlString = htmlString.replace(/%s4%/g, Utils.getUrlParam(objectData, "backgroundColor="));
    htmlString = htmlString.replace(/%s5%/g, Utils.getUrlParam(objectData, "scaleToFit="));

    return htmlString;
  };

  let _addImageScript = (presentation, html, containerName, htmlName, transition) => {
    html = html.replace(/'/g, "\\'");

    const scriptTag = IMAGE_SCRIPT.replace(/%s1/g, html)
						.replace(/%s2/g, containerName)
						.replace(/%s3/g, htmlName)
						.replace(/%s4/g, transition);

    PresentationParser.addScriptTag(presentation, scriptTag);
  };

  let _updateHtmlUrl = (presentation) => {
    let transition = "none";
    const url = playlistItem.objectData;

//		int height = 0, width = 0;

//		if (placeholderInfo.getWidthUnits() === '%') {
//			width = (int)((placeholderInfo.getWidth() / 100.0) * Window.getClientWidth());
//			height = (int)((placeholderInfo.getHeight() / 100.0) * Window.getClientHeight());
//		}
//		else {
//			height = (int)placeholderInfo.getHeight();
//			width = (int)placeholderInfo.getWidth();
//		}

//		double scale = ViewerUtils.getItemScale(playlistItem.getScale());

//		height = (int)(height * scale);
//		width = (int)(width * scale);

    transition = placeholderInfo.transition;

    const scriptTag = URL_SCRIPT.replace(/%s1/g, url)
						.replace(/%s2/g, phName)
						.replace(/%s3/g, htmlName)
						.replace(/%s4/g, transition);

    PresentationParser.addScriptTag(presentation, scriptTag);
  };

  const _showSubscriptionError = () => {
    if (!subscriptionError) { return; }

    const transition = placeholderInfo.transition;
    const errorContent = SUBSCRIPTION_ERROR_HTML.replace(/%s1/g, subscriptionError);

    Utils.destroyFrameElement(presFrame, htmlName, phName);

    // Timeout needed because 'destroyFrameElement' has a 100ms timeout
    // on actually destroying the element
    setTimeout(() => {
      if (playlistItem.playUntilDone) {
        playlistItem.playUntilDone = false;
        playlistItem.duration = 10;
      }

      isError = false;
      isReady = false;

      Utils.addTextHtml(errorContent, presFrame, phName, htmlName, transition);
    }, 200);
  };

  const _subscriptionStatusResponse = (authorized) => {
    if (!isError) { return; }

    if (authorized) {
      isError = false;
    } else if (++subscriptionResponseCount >= 2) {
      subscriptionError = PRESENTATION_SUBSCRIPTION_ERROR;

      if (isReady) {
        _showSubscriptionError();
      }
    }
  };

  let _checkSubscriptionStatus = () => {
    if (ConfigurationFactory.isEmbed() || !ConfigurationFactory.isDisplay()) {
      _subscriptionStatusResponse(true);

      return;
    }

    // Check subscription authorized status for embedded presentations
    const displayId = ConfigurationFactory.getDisplayId();

    SubscriptionStatusProvider.checkDisplayAuthorizationStatus(displayId, Global.EMBEDDED_PRESENTATIONS_PC, (authorized) => {
      Logger.log(`Subscription authorized status for Embedded Presentation: ${authorized}`);
      Logger.logExternalMessage(`Subscription authorized status for Embedded Presentation: ${authorized}`);
      Logger.viewerInfo(`Subscription authorized status for Embedded Presentation: ${authorized}`);

      _subscriptionStatusResponse(authorized);
    });

    // Check subscription authorized status for Professional Display
    SubscriptionStatusProvider.checkDisplayAuthorizationStatus(displayId, Global.PLAYER_PRO_PC, (authorized) => {
      Logger.log(`Subscription authorized status for Player Professional: ${authorized}`);
      Logger.logExternalMessage(`Subscription authorized status for Player Professional: ${authorized}`);
      Logger.viewerInfo(`Subscription authorized status for Player Professional: ${authorized}`);

      _subscriptionStatusResponse(authorized);
    });
  };

  factory.setReady = (frame, canPlay, canStop, canPause, canReportReady, canReportDone) => {
    presFrame = frame;

    if (!isReady) {
      isReady = true;

      if (isError) {
        _showSubscriptionError();
      }

      if (onGadgetReady) {
        onGadgetReady();
      }

			// if GadgetReadyCommand is null, than this is a single Gadget
			// in placeholder (no ready command, just Start playing right away)
      else if (!isPlaying) {
        factory.play(true);
      }
    }
  };

  factory.setError = (presFrame, reason) => {
		// catch gadget error (not implemented)
  };

  factory.setDone = () => {
    if (isPlaying) {
      if (onGadgetDone) {
        isPlaying = false;
        onGadgetDone();
      }
      else {
        factory.stop(false);
        factory.play(false);
      }
    }
  };

  factory.play = (show) => {
    if (!isPlaying) {
      isPlaying = true;
      if (isReady) {
        Utils.playCommand(presFrame, htmlName, show);
      }
    }
  };

  factory.stop = (hide) => {
//		if (isPlaying) {
    isPlaying = false;

    Utils.stopCommand(presFrame, htmlName, hide);
//		}
  };

  factory.pause = (hide) => {
		// removed isPlaying check (since pause can be called on a 'paused' item to hide it)
//		if (isPlaying) {
    isPlaying = false;

    Utils.pauseCommand(presFrame, htmlName, hide);
//		}
  };

  factory.unload = () => {
    isReady = false;
  };

  factory.isReady = () => {
    return isReady && !isError;
  };

  factory.getItem = () => {
    return playlistItem;
  };

  (function () {
    PlaylistItemUtils.registerGadget(htmlName, factory);
  }());

  return factory;
};

module.exports = PlaylistItemController;
