const AnimationHelper = require("./AnimationHelper");

const simpleTransition = (property, duration, propertyProvider) => {
  const transitionDef = property + " " + (duration / 1000) + "s";

  return {
    onEnterDelay: duration || 0,
    onLeaveDelay: duration || 0,
    onEnterSetup: (element) => {
      element.style[property] = propertyProvider().enterValue;
    },
    onEnter: (element) => {
      element.style[property] = propertyProvider().targetValue;
      element.style.transition = transitionDef;
    },
    onLeaveSetup: (element) => {
      element.style[property] = propertyProvider().targetValue;
    },
    onLeave: (element) => {
      element.style[property] = propertyProvider().leaveValue;
      element.style.transition = transitionDef;
    }
  };
};

const classTransition = (className, enterDuration, leaveDuration) => {
  return {
    onEnterDelay: enterDuration || 0,
    onLeaveDelay: leaveDuration || 0,
    onEnterSetup: (element) => {
    },
    onEnter: (element) => {
      element.classList.add(className);
    },
    onLeaveSetup: (element) => {
    },
    onLeave: (element) => {
    }
  };
};

const overlayClassTransition = (className, enterDuration, leaveDuration) => {
  return {
    onEnterDelay: enterDuration || 0,
    onLeaveDelay: leaveDuration || 0,
    onEnterSetup: (element) => {
      const overlay = document.createElement("div");

      overlay.setAttribute("id", element.id + "_overlay");
      overlay.style.position = "absolute";
      overlay.style.left = 0;
      overlay.style.top = 0;
      overlay.style.width = "100%";
      overlay.style.height = "100%";
      overlay.style.zIndex = 100;
      overlay.classList.add(className);
      element.parentElement.appendChild(overlay);
    },
    onEnter: (element) => {
      setTimeout(() => {
        const overlay = document.getElementById(element.id + "_overlay");
        element.parentElement.removeChild(overlay);
        overlay.remove();
      }, enterDuration);
    },
    onLeaveSetup: (element) => {
      element.style.visibility = "hidden";
      element.style.zIndex = -100;
    },
    onLeave: (element) => {
    }
  };
};

const createTransitionHandlers = (containerName) => {
  return {
    normal: {
      onLeaveDelay: 0,
      onEnterDelay: 0,
      onEnterSetup: (element) => {
      },
      onEnter: (element) => {
      },
      onLeaveSetup: (element) => {
      },
      onLeave: (element) => {
      }
    },
    slideFromLeft: simpleTransition("left", 1000, () => {
      return {
        enterValue: -getSize(containerName).parentWidth,
        targetValue: 0,
        leaveValue: getSize(containerName).parentWidth
      };
    }),
    slideFromRight: simpleTransition("left", 1000, () => {
      return {
        enterValue: getSize(containerName).parentWidth,
        targetValue: 0,
        leaveValue: -getSize(containerName).parentWidth
      };
    }),
    slideFromTop: simpleTransition("top", 1000, () => {
      return {
        enterValue: -getSize(containerName).parentHeight,
        targetValue: 0,
        leaveValue: getSize(containerName).parentHeight
      };
    }),
    slideFromBottom: simpleTransition("top", 1000, () => {
      return {
        enterValue: getSize(containerName).parentHeight,
        targetValue: 0,
        leaveValue: -getSize(containerName).parentHeight
      };
    }),
    fadeIn: classTransition("transitionFadeIn", 1000, 1000),
    zoomIn: classTransition("transitionZoomIn", 1000, 1000),
    stripesHorizontal: overlayClassTransition("stripesHorizontal", 1000, 0),
    stripesVertical: overlayClassTransition("stripesVertical", 1000, 0)
  };
};

const getSize = function (containerName) {
  const container = document.getElementById(containerName);
  const parentWidth = container && container.clientWidth;
  const parentHeight = container && container.clientHeight;

  return { parentWidth, parentHeight };
};

let lastTransitionName = null;

const TransitionHandler = (containerName, htmlName, transitionType) => {
  const factory = {};
  const transitionName = transitionType || "normal";
  let transitionHandlers = createTransitionHandlers(containerName);

  factory.resetTransition = (element) => {
    element.style.transition = "none";
    element.style.overflow = "hidden";
    element.style.opacity = 1;
    element.style.left = 0;
    element.style.top = 0;

    element.classList = "";
  };

  factory.show = () => {
    const transition = transitionHandlers[transitionName] || transitionHandlers.normal;
    const element = window.getElement(htmlName);

    if (!element) { return; }

    lastTransitionName = transitionName;

    factory.resetTransition(element);
    transition.onEnterSetup && transition.onEnterSetup(element);
    window.showElement(htmlName);

    setTimeout(() => {
      element.style.zIndex = 10;
      transition.onEnter && transition.onEnter(element);
    });
  };

  factory.hide = (callback) => {
    const hideTransitionName = lastTransitionName || transitionName;
    const transition = transitionHandlers[hideTransitionName] || transitionHandlers.normal;
    const element = window.getElement(htmlName);

    if (!element) {
      callback && callback();

      return;
    }

    factory.resetTransition(element);
    transition.onLeaveSetup && transition.onLeaveSetup(element);

    setTimeout(() => {
      element.style.zIndex = 0;
      transition.onLeave && transition.onLeave(element);

      setTimeout(() => {
        window.hideElement(htmlName);

        callback && callback();
      }, transition.onLeaveDelay);
    });
  };

  // unit tests only
  factory._setTransitionHandlers = (handlers) => {
    transitionHandlers = handlers;
  };

  return factory;
};

AnimationHelper.createStripeClass("stripesHorizontal", 200, 0, "#000000ff");
AnimationHelper.createStripeClass("stripesVertical", 200, 90, "#000000ff");

module.exports = TransitionHandler;
