const Logger = {};

const Config = require("./Config/Config");
const Global = require("./Config/Global");
const ExtLogger = require("./ExtLogger");
const EndpointLogger = require("bq-endpoint-logger");
const platformData = require("platform");

let endpointLogger;
let endpointLoggerContentFields = {
  scheduleId: null,
  presentationId: null,
  placeholderId: null,
  componentId: null,
  scheduleItemUrl: null
};

Logger.initEndpointLogger = (config = {}) => {
  endpointLogger = EndpointLogger.init(config);
};

Logger.setEndpointLoggerContentFields = (contentFields = {}) => {
  endpointLoggerContentFields = Object.assign({}, endpointLoggerContentFields, contentFields);
};

Logger.logDebug = (msg) => {
  if (Config.ENABLE_DEBUG_LOGGING) {
    Logger.log(msg);
  }

  Logger.viewerDebug(msg);
};

Logger.log = (msg, e) => {
  if (window.writeToLog) {
    window.writeToLog(msg);
  }

  /* eslint-disable no-console */
  if (e) {
    console.log(msg, e);
  } else {
    console.log(msg);
  }
};

Logger.logException = (methodName, err, identifier, additionalData) => {
  let eventDetails;
  if (err) {
    eventDetails = err.toString();
  }

  if (identifier) {
    eventDetails = `${eventDetails} - ${identifier}`;
  }

  const eventName = `Exception - ${methodName}`;
  let additionalDataStr;

  if (additionalData) {
    additionalDataStr = JSON.stringify(additionalData);
  }

  const viewerLogMsg = additionalDataStr ? `${eventName} - ${additionalDataStr}` : eventName;

  if (window.writeToLog) {
    window.writeToLog(viewerLogMsg);
  }

  if (additionalData) {
    /* eslint-disable no-console */
    console.log(eventName, err, identifier, additionalData);
  } else {
    console.log(eventName, err, identifier);
  }
  Logger.logExternalMessage(eventName, eventDetails, true);
  Logger.viewerDebug(eventName, eventDetails);
};

Logger.logExternalMessage = (eventName, eventDetails, noConsoleLog) => {
  ExtLogger.logViewerEvent(eventName, eventDetails, noConsoleLog);
};

const commonLogFields = { // common to viewer heartbeats and viewer log events
  eventApp: "Viewer",
  eventAppVersion: Global.VIEWER_VERSION,
  osVersion: `${platformData.os.family} ${platformData.os.architecture} ${platformData.os.version || ""}`,
  browserVersion: `${platformData.name} ${platformData.version}`,
  userAgent: platformData.ua
};

Logger.recordUptimeHeartbeat = (details = {}) => {
  endpointLogger.uptimeHeartbeat(Object.assign({}, commonLogFields, endpointLoggerContentFields, details))
  .catch(console.error);
};

Logger.heartbeatInterval = () => {
  return endpointLogger.getHeartbeatIntervalMS();
};

Logger.viewerInfo = eventDetails => {
  return endpointLogger.info(Object.assign({}, commonLogFields, endpointLoggerContentFields, {eventDetails}));
};

Logger.viewerError = (eventErrorCode, eventDetails, debugInfo)  => {
  return endpointLogger.error(Object.assign({}, commonLogFields, endpointLoggerContentFields, {eventErrorCode, eventDetails, debugInfo}));
};

Logger.viewerDebug = (eventDetails, debugInfo) => {
  return endpointLogger.debug(Object.assign({}, commonLogFields, endpointLoggerContentFields, {eventDetails, debugInfo}));
};

Logger.viewerWarning = (eventDetails, debugInfo) => {
  return endpointLogger.warning(Object.assign({}, commonLogFields, endpointLoggerContentFields, {eventDetails, debugInfo}));
};

Logger.logTemplateEvent = (eventFields = {}) => {
  if (!eventFields.severity || !eventFields.eventApp || !eventFields.eventAppVersion) {return;}

  const severity = eventFields.severity.toLowerCase();
  const logFn = endpointLogger[severity];
  delete eventFields.severity;

  return logFn(Object.assign({}, commonLogFields, endpointLoggerContentFields, eventFields));
};

Logger.logServiceWorkerEvent = (eventFields = {}) => {
  if (!eventFields.severity || !eventFields.eventDetails) { return; }

  const severity = eventFields.severity.toLowerCase();
  const logFn = endpointLogger[severity];
  delete eventFields.severity;

  return logFn(Object.assign({}, commonLogFields, endpointLoggerContentFields, eventFields));
};


Logger.logAppsEvent = (eventName) => {
  ExtLogger.logAppsEvent(eventName);
};

Logger.logPlayerConfigData = (config, cb) => {
  ExtLogger.logPlayerConfigData(config, cb);
};

Logger.logSharedScheduleError = (errorMsg, scheduleItem) => {
  ExtLogger.logSharedScheduleError(errorMsg, scheduleItem);
};

module.exports = Logger;
