/* eslint-disable class-methods-use-this */
const ConfigurationFactory = require("../ConfigurationFactory");

class ViewerZoomController {
  constructor() {
    this.$elem = window.$("#zoom-selector");
    this._zoomLevel = 1;
    this._zoomAxis = null;
    this._containerIds = [];
    this._containerSizes = [];
    this._containerZoomLevels = [];
    this._containerZoomAxes = [];

    this._populatePieces();

    this._initHandlers();
  }

  _populatePieces() {
    const $c = this.$elem;
    this.$zoomInButton = $c.find("#zoom-in");
    this.$zoomOutButton = $c.find("#zoom-out");
    this.$zoomFitButton = $c.find("#zoom-fit");
    this.$zoomNumberLabel = $c.find("#zoom-number");
  }

  _initHandlers() {
    const _this = this;

    this.$zoomInButton.click(() => { return _this._zoomIn(); });
    this.$zoomOutButton.click(() => { return _this._zoomOut(); });
    this.$zoomFitButton.click(() => { return _this.zoomFit(); });
  }

  _canZoomIn() {
    return this._zoomLevel <= 1.99;
  }

  _canZoomOut() {
    return this._zoomLevel >= 0.21;
  }

  _zoomIn() {
    if (this._canZoomIn()) {
      this._zoomLevel = Math.floor(this._zoomLevel * 10 + 1) / 10;
      this._zoomAxis = null;

      this.applyZoom();
    }
  }

  _zoomOut() {
    if (this._canZoomOut()) {
      this._zoomLevel = Math.ceil(this._zoomLevel * 10 - 1) / 10;
      this._zoomAxis = null;

      this.applyZoom();
    }
  }

  _shouldShowPoweredByRiseBar() {
    const platform = ConfigurationFactory.getEnv(true);
    return ConfigurationFactory.isSharedschedule() && !ConfigurationFactory.isEmbed() && platform !== "embed" && platform.indexOf("apps") !== 0;
  }

  zoomFit() {
    let artboardWidth = 0, artboardHeight = 0, containerFitWidth = 0, containerFitHeight = 0, containerFitRatio = 1, containerZoomAxis = "x";

    if (!this._containerSizes.length) {
      return;
    }

    this._containerZoomLevels = [];
    this._containerZoomAxes = [];

    const workspaceWidth = document.body.clientWidth;
    const workspaceHeight = document.body.clientHeight - (this._shouldShowPoweredByRiseBar() ? 25 : 0);

    for (let i = 0; i < this._containerSizes.length; i++) {
      artboardWidth = Math.max(artboardWidth, this._containerSizes[i][0]);
      artboardHeight = Math.max(artboardHeight, this._containerSizes[i][1]);

      containerFitWidth = workspaceWidth / this._containerSizes[i][0];
      containerFitHeight = workspaceHeight / this._containerSizes[i][1];
      containerFitRatio = Math.min(containerFitWidth, containerFitHeight);
      containerFitRatio = Math.min(5, containerFitRatio);
      containerFitRatio = Math.max(0.05, containerFitRatio);
      containerZoomAxis = (containerFitWidth > containerFitHeight) ? "x" : "y";

      this._containerZoomLevels.push(containerFitRatio);
      this._containerZoomAxes.push(containerZoomAxis);
    }

    const fitWidth = workspaceWidth / artboardWidth;
    const fitHeight = workspaceHeight / artboardHeight;
    let fitRatio = Math.min(fitWidth, fitHeight);
    fitRatio = Math.min(5, fitRatio);
    fitRatio = Math.max(0.05, fitRatio);

    this._zoomLevel = fitRatio;
    this._zoomAxis = (fitWidth > fitHeight) ? "x" : "y";

    this.applyZoom();
  }

  applyZoom() {
    if (!ConfigurationFactory.isDisplay() && this._containerIds.length) {
      let topOnZero = false;
      let maxTop = 0;
      let zoomLevels = "";

      for (let i = 0; i < this._containerIds.length; i++) {
        const $c = window.$(`#iFrame_${this._containerIds[i]}`);

        if (!$c || !$c[0] || $c[0].offsetTop === undefined) { continue; }

        let translate = "", translateX = "0px", translateY = "0px";

        if (ConfigurationFactory.isSharedschedule()) {
          if (!this._containerZoomAxes[i] || this._containerZoomAxes[i] === "x") {
            $c.css("left", "50%");
            translateX = "-50%";
          } else {
            $c.css("left", "0px");
          }

          if (!this._containerZoomAxes[i] || this._containerZoomAxes[i] === "y") {
            if (this._shouldShowPoweredByRiseBar()) {
              $c.css("top", "calc(50% - 12px)");
            } else {
              $c.css("top", "50%");
            }
            translateY = "-50%";
          } else {
            $c.css("top", "0px");
            topOnZero = true;
          }

          translate = ` translate(${translateX}, ${translateY})`;
        }

        if (ConfigurationFactory.isSharedschedule()) {
          $c.css("transform", `scale(${this._containerZoomLevels[i]})${translate}`);
        } else {
          $c.css("transform", `scale(${this._zoomLevel})${translate}`);
        }

        zoomLevels += `${Math.round(this._containerZoomLevels[i] * 100)}%` + " ";

        $c.css("transform-origin", "0% 0%");
        $c.css("transition", this._shouldShowPoweredByRiseBar() ? "all 0s" : "all .4s");

        maxTop = Math.max(maxTop, $c[0].offsetTop + $c[0].offsetHeight * this._zoomLevel / 2);
      }

      zoomLevels = zoomLevels.trim();

      if (this._shouldShowPoweredByRiseBar()) {
        const poweredByRiseDiv = window.document.getElementById("powered-by-rise-div");
        poweredByRiseDiv.style.visibility = "visible";
        if (topOnZero) {
          poweredByRiseDiv.style.top = "initial";
          poweredByRiseDiv.style.bottom = "0";
        } else {
          poweredByRiseDiv.style.top = maxTop + "px";
          poweredByRiseDiv.style.bottom = "initial";
        }
      }
      this.$zoomNumberLabel.text(ConfigurationFactory.isSharedschedule() ? zoomLevels : `${Math.round(this._zoomLevel * 100)}%`);
    }
  }

}

let instance;

// singleton
function _getInstance() {
  if (!instance) {
    instance = new ViewerZoomController();
  }

  return instance;
}

ViewerZoomController.init = (containerId, width, height) => {
  _getInstance()._zoomLevel = 1;
  _getInstance()._containerIds.push(containerId);
  _getInstance()._containerSizes.push([width, height]);

  if (ConfigurationFactory.isSharedschedule()) {
    _getInstance().zoomFit();
  } else {
    _getInstance().applyZoom();
  }
};

ViewerZoomController.zoomFit = () => {
  _getInstance().zoomFit();
};

ViewerZoomController.resetContainers = () => {
  _getInstance()._containerIds = [];
  _getInstance()._containerSizes = [];
};

module.exports = ViewerZoomController;
