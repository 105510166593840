const Global = require("./Config/Global");
const Config = require("./Config/Config");
const Logger = require("./Logger");
const crypto = require("crypto");

const utils = {};

utils.getBrowserVersion = (msg) => {
  try {
    // from http://stackoverflow.com/questions/5916900/detect-version-of-browser
    const N = window.navigator.appName;
    const ua = window.navigator.userAgent;
    let tem;

    let M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
    if (M && (tem = ua.match(/version\/([\.\d]+)/i)) !== null) {
      M[2] = tem[1];
    }
    M = M ? [M[1], M[2]] : [N, navigator.appVersion, "-?"];

    // return M[0] + ' ' + M[1];
    return M;
  } catch (err) {
    Logger.logException("Utils.getBrowserVersion", err);
  }
};

utils.decodeQueryString = (encodedURLComponent) => {
  const regexp = /\+/g;
  return decodeURIComponent(encodedURLComponent.replace(regexp, "%20"));
};

utils.getUrlParam = function (queryString, param) {
  if (queryString && param) {
    const paramIndex = queryString.toLowerCase().indexOf(param.toLowerCase());

    if (paramIndex !== -1) {
      const endIndex = queryString.indexOf("&", paramIndex + 1);
      if (endIndex !== -1) {
        return utils.decodeQueryString(queryString.substring(paramIndex + param.length, endIndex));
      } else {
        return utils.decodeQueryString(queryString.substring(paramIndex + param.length));
      }
    }
  }

  return null;
};

utils.getUniqueId = function () {
  let uniqueId = window.localStorage.uniqueId || "";
  if (uniqueId === "") {
    uniqueId = crypto.randomBytes(16).toString("hex");
    window.localStorage.setItem("uniqueId", uniqueId);
  }
  return uniqueId;
};

utils.getFromQueryString = function (param) {
  let queryString = window.location.search;

  if (queryString && queryString.charAt(0) === "?") {
    queryString = "&" + queryString.substring(1);
    param = "&" + param;

    return utils.getUrlParam(queryString, param);
  }

  return null;
};

utils.playCommand = (presFrame, htmlName, show) => {
  try {
    window.playCmd(presFrame, htmlName);

    if (show) {
      window.document.getElementById(presFrame).contentWindow.showElement(htmlName);
    }
  } catch (err) {
    Logger.log(`Exception - Utils.playCommand - ${htmlName}`, err);
  }
};

utils.stopCommand = (presFrame, htmlName, hide) => {
  try {
    window.stopCmd(presFrame, htmlName);

    if (hide) {
      window.document.getElementById(presFrame).contentWindow.hideElement(htmlName);
    }
  } catch (err) {
    Logger.log(`Exception - Utils.stopCommand - ${htmlName}`, err);
  }
};

utils.pauseCommand = (presFrame, htmlName, hide) => {
  try {
    window.pauseCmd(presFrame, htmlName);

    if (hide) {
      window.document.getElementById(presFrame).contentWindow.hideElement(htmlName);
    }
  } catch (err) {
    Logger.log(`Exception - Utils.pauseCommand - ${htmlName}`, err);
  }
};

utils.embedReady = () => {
  try {
    window.embedReady();
  } catch (err) {
    Logger.logException("Utils.embedReady", err);
  }
};

utils.embedDone = () => {
  try {
    window.embedDone();
  } catch (err) {
    Logger.logException("Utils.embedDone", err);
  }
};

utils.showElement = function (elementName, show) {
  try {
    if (show) {
      window.showElement(elementName);
    } else {
      window.hideElement(elementName);
    }
  } catch (err) {
    Logger.logException("Utils.showElement", err, elementName);
  }
};

utils.showFrameElement = (presFrame, elementName, show) => {
  try {
    window.showFrameElement(presFrame, elementName, show);
  } catch (err) {
    Logger.logException("Utils.showFrameElement", err, elementName);
  }
};

utils.addGadgetHtml = (presFrame, frameName, html) => {
  try {
    window.document.getElementById(presFrame).contentWindow.addGadgetHtml(frameName, html);
  } catch (err) {
    Logger.logException("Utils.addGadgetHtml", err, frameName);
  }
};

utils.addTextHtml = (text, presFrame, frameName, htmlName, transition) => {
  try {
    window.document.getElementById(presFrame).contentWindow.updateText(text, frameName, htmlName, transition);
  } catch (err) {
    Logger.logException("Utils.addTextHtml", err, presFrame);
  }
};

utils.destroyContainer = (elementName) => {
  utils.destroyElement(elementName, "mainDiv");
};

utils.destroyElement = (elementName, containerName) => {
  window.destroyElement(elementName, containerName);
};

utils.destroyFrameElement = (presFrame, elementName, containerName) => {
  try {
    window.document.getElementById(presFrame).contentWindow.destroyElement(elementName, containerName);
  } catch (err) {
    Logger.logException("Utils.destroyFrameElement", err, elementName);
  }
};

utils.setBackground = function (elementName, color) {
  try {
    window.setBackground(elementName, color);
  } catch (err) {
    Logger.logException("Utils.setBackground", err, elementName);
  }
};

utils.resizeContainer = function (elementName, width, height) {
  try {
    $("#" + elementName).css("width", width);
    $("#" + elementName).css("height", height);
  } catch (err) {
    Logger.logException("Utils.resizeContainer", err, elementName);
  }
};

utils.reportViewerConfigToPlayer = () => {
  const viewerVersion = Global.VIEWER_VERSION,
    width = window.innerWidth,
    height = window.innerHeight;

  if (!window.postToPlayer) {
    Logger.log("WARN - Utils.reportViewerConfigToPlayer: window.postToPlayer doesn't exist.");
  } else {
    try {
      window.postToPlayer({ message: "viewer-config", viewerVersion, width, height });
    } catch (err) {
      window.logException("Utils.reportViewerConfigToPlayer", err);
    }
  }
};

utils.isTestEnvironment = () => {
  return window.env && window.env.RISE_ENV && window.env.RISE_ENV === "test";
};

utils.isFeatureEnabled = (companyId = RiseVision.Viewer.Data.ViewerDataInfo.getItemCompanyId()) => {
  return !Config.TESTING_COMPANIES || Config.TESTING_COMPANIES.includes(companyId);
};

utils.attachDOMListener = (htmlName, event, callback) => {
  const el = document.getElementById(htmlName);

  if (!el) { return; }

  el.addEventListener(event, () => {
    Logger.logDebug(`attachDOMListener - ${htmlName} - ${event}!`);

    callback && callback();
  }, false);
};

module.exports = utils;
