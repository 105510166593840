const Logger = require("./Logger");
const Utils = require("./Utils");

const service = {};

service.DISPLAY = "display";
service.SCHEDULE = "schedule";
service.SHAREDSCHEDULE = "sharedschedule";
service.PRESENTATION = "presentation";
service.EXTENSION = "extension";
service.SHARED_SCHEDULE_EMBED = "embed";

service.DEMO_ID = "demo";

const TYPE_PARAM = "type=";
const ID_PARAM = "id=";
const CLAIM_ID_PARAM = "claimId=";
const PARENT_ID_PARAM = "parentId=";
const DISPLAY_ID_PARAM = "displayId=";
const AUTH_KEY_PARAM = "CompanyAuthKey=";
const DISPLAY_ADDRESS_PARAM = "DisplayAddress=";
const SHOW_UI_PARAM = "showUi=";
const SYS_INFO_PARAM = "sysInfo=";
const ENV_PARAM = "env=";
const RUNNING_INTERVAL = 21600000; // 6 hours
const RUNNING_MESSAGE = "running";

let type;
let id;
let claimId;
let parentId;
let showUi = true;
let env;

let isEmbed = false;
let sysInfo;

let isShowingBlack = false, isShowingProgressBar = true;

service.updateParameters = () => {
  // example: queryString = '?type=display&id=1d3b23d3-31f6-4ad8-b8b1-c180231b9919';
  // example: queryString = '?type=display&id=1d3b23d3-31f6-4ad8-b8b1-c180231b9919&CompanyAuthKey=';
  // example: queryString = '?type=preview'; **** DEPRECATED
  // example: queryString = '?type=presentation&id=1d3b23d3-31f6-4ad8-b8b1-c180231b9919&showui=false';
  type = Utils.getFromQueryString(TYPE_PARAM);
  if (type == null) {
    type = service.DISPLAY;
  }

  id = Utils.getFromQueryString(ID_PARAM);
  if (!id) {
    id = service.DEMO_ID;
  }

  parentId = Utils.getFromQueryString(PARENT_ID_PARAM);
  if (parentId != null) {
    isEmbed = true;
  } else {
    isEmbed = false;
  }

  claimId = Utils.getFromQueryString(CLAIM_ID_PARAM);
  showUi = !Utils.getFromQueryString(SHOW_UI_PARAM) || Utils.getFromQueryString(SHOW_UI_PARAM).toLowerCase() !== "false";
  sysInfo = Utils.getFromQueryString(SYS_INFO_PARAM);
  env = Utils.getFromQueryString(ENV_PARAM);
};

service.useContentSentinel = () => {
  return service.isSharedschedule() || service.isExtension();
};

service.endpointType = () => {
  if (service.isExtension()) { return "Extension"; }
  if (service.isDisplay()) { return "Display"; }
  if (service.isInApp()) { return "InApp"; }
  if (service.isEmbedSharedSchedule()) { return "Embed"; }
  if (service.isSharedschedule()) { return "URL"; }

  return "Unknown";
};

service.displayLogging = () => {
  if (!service.isDisplay()) { return; }

  Logger.logExternalMessage("startup", JSON.stringify({
    isEmbed,
    href: window.location.href,
    postToPlayer: typeof window.postToPlayer,
    receiveFromPlayer: typeof window.receiveFromPlayer,
    disableViewerContentFetch: window.disableViewerContentFetch,
    useWindowMessagingForLocalMessaging: window.useWindowMessagingForLocalMessaging
  }));
  Logger.viewerDebug("startup", JSON.stringify({
    isEmbed,
    href: window.location.href,
    postToPlayer: typeof window.postToPlayer,
    receiveFromPlayer: typeof window.receiveFromPlayer,
    disableViewerContentFetch: window.disableViewerContentFetch,
    useWindowMessagingForLocalMessaging: window.useWindowMessagingForLocalMessaging
  }));

  // loads static methods to be called through JSNI
  Utils.reportViewerConfigToPlayer();

  // log imlive event every 6 hours so a display that does not restart everyday is counted as active on the logs
  Logger.logExternalMessage(RUNNING_MESSAGE);
  setInterval(() => {
    Logger.logExternalMessage(RUNNING_MESSAGE);
  }, RUNNING_INTERVAL);
};

service.getType = () => { return type; };

service.isValidType = function () {
  return type !== null &&
    (type === service.DISPLAY || type === service.PRESENTATION || type === service.SCHEDULE || type === service.SHAREDSCHEDULE);
};

service.isDisplay = function () {
  return service.DISPLAY === type;
};

service.isInApp = function () {
  return service.getEnv(true).indexOf("apps_") === 0;
};

service.isExtension = function () {
  return service.getEnv() === service.EXTENSION;
};

service.isSharedschedule = function () {
  return service.SHAREDSCHEDULE === type;
};

service.isPresentation = function () {
  return service.PRESENTATION === type;
};

service.isPreview = function () {
  return (service.PRESENTATION === type || service.SCHEDULE === type) && !isEmbed;
};

service.isEmbed = function () {
  return isEmbed;
};

service.isEmbedSharedSchedule = function () {
  return service.isSharedschedule() && service.getEnv() === service.SHARED_SCHEDULE_EMBED;
};

service.getDisplayId = function () {
  if (service.isDisplay()) {
    if (isEmbed) {
      return Utils.getFromQueryString(DISPLAY_ID_PARAM);
    }
    else {
      return id;
    }
  }
  return "";
};

service.getScheduleId = function () {
  if (service.isSharedschedule()) {
    return id || "";
  }

  return "";
};

service.getId = () => { return id; };

service.getParentId = () => { return parentId; };

service.getClaimId = () => { return claimId; };

service.getSysInfo = function () {
  return sysInfo ? sysInfo : "";
};

service.getShowUi = () => { return showUi; };

service.getEnv = (includeDefault) => {
  return includeDefault ? (env || "url") : env;
};

service.checkDistribution = function (distribution) {
  if (!service.isDisplay() || !distribution) {
    return true;
  }

  const displayId = service.getDisplayId();
  for (let i = 0; i < distribution.length; i++) {
    if (distribution[i] === displayId) {
      return true;
    }
  }
  return false;
};

module.exports = service;
