const Config = require("../Config/Config");
const Logger = require("../Logger");
const ConfigurationFactory = require("../ConfigurationFactory");
const Utils = require("../Utils");

const service = {};
const HTML_TEMPLATE_URL = "https://widgets.risevision.com/STAGE/templates/PCODE/src/template.html?presentationId=PID&frameElementId=FRAMEID&type=TYPE&viewerId=VIEWERID";

service.render = (presentation, htmlName, containerName) => {
  const width = ConfigurationFactory.isSharedschedule() ? 1920 : window.innerWidth;
  const height = ConfigurationFactory.isSharedschedule() ? 1080 : window.innerHeight;
  const top = 0, left = 0;

  Logger.logDebug(`HTMLTemplateRenderer rendering ${htmlName} into container ${containerName}, presentation: ${JSON.stringify(presentation && presentation.name)}`);

  if (!presentation || !htmlName || !containerName) {
    return;
  }

  let url = HTML_TEMPLATE_URL
    .replace("STAGE", Config.HTML_TEMPLATE_ENV)
    .replace("PCODE", presentation.productCode)
    .replace("PID", presentation.id)
    .replace("FRAMEID", "iFrame_" + htmlName)
    .replace("TYPE", ConfigurationFactory.getType())
    .replace("VIEWERID", Utils.getUniqueId());

  if (ConfigurationFactory.isSharedschedule()) {
    url += "&env=" + ConfigurationFactory.getEnv(true);
  }

  _addTemplate(htmlName, containerName, width, height, top, left, 0, 0, url);

  RiseVision.Viewer.UI.ViewerZoomController.init(htmlName, width, height);
};

let _addTemplate = (frameName, containerName, width, height, top, left, scrollx, scrolly, url) => {
  window.createURL(frameName, containerName, width, height, top, left, scrollx, scrolly, url);
};

module.exports = service;
