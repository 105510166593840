const Logger = require("../Logger");
const Global = require("../Config/Global");

const service = {};

let isActive = false;
const baseUrl = "http://localhost:9449/";
const cmdRestart = "restart";
const cmdShutdown = "shutdown";
const cmdSave = "save_property";
const cmdSetProperty = "set_property";
const cmdHeartbeat = "heartbeat";

const paramRestartRequired = "restart_required";
const paramRebootRequired = "reboot_required";
const paramRebootTime = "reboot_time";
const paramRebootEnabled = "reboot_enabled";
const paramUpdateRequired = "update_required";
const paramOrientation = "orientation";

const displayCommand = "display_command";

const HEARTBEAT_TIME = 1 * Global.MINUTE_UPDATE_INTERVAL;
let heartbeatInterval;

const _pingResponseStatic = function () {
  // this is not necessary as we detect Player's presence from 'player=true' parameter in Viewer's URL
  // isActive = true;
};

const _callUrlNative = function (url, action) {
  if (!jQuery || !jQuery.getJSON) { return; }

  Logger.log("Rise Player request - " + action);

  jQuery.getJSON(url, { format: "json" }, () => {
    try {
      Logger.log("Rise Player response - active");

      _pingResponseStatic();
    }
    catch (err) {
      Logger.logException("RisePlayerController._callUrlNative.jquery.getJSON.callback", err, url);
    }
  })
  .fail((jqXHR, textStatus, error) => {
    Logger.logException("RisePlayerController._callUrlNative", error, url, textStatus);
  });
};

service.setIsActive = function (value) {
  if (value && value.toLowerCase() === "true") {
    isActive = true;
  }

  _pingPlayer();
};

service.getIsActive = function () {
  return isActive;
};

const _getTimestampParam = function () {
  return "timestamp=" + new Date().getTime();
};

service.restart = function () {
  const url = baseUrl + cmdRestart + "?" + _getTimestampParam();
  _callUrlNative(url, cmdRestart);
};

service.shutdown = function () {
  const url = baseUrl + cmdShutdown + "?" + _getTimestampParam();
  _callUrlNative(url, cmdShutdown);
};

service.saveAndRestart = function (displayId, claimId) {
  const url = baseUrl + cmdSave + "?restart_viewer=true&display_id=" + displayId + "&claim_id=" + claimId + "&" + _getTimestampParam();
  _callUrlNative(url, cmdSave);
};

service.setPlayerInfo = function (data) {
  if (isActive) {
    let restartRequired, rebootRequired, rebootTime, rebootEnabled, updateRequired, orientation = 0;

    if (data && data.player) {
      restartRequired = data.player.restartRequired;
      rebootRequired = data.player.rebootRequired;
      updateRequired = data.player.updateRequired;
    }

    if (data && data.display) {
      rebootTime = data.display.restartTime;
      rebootEnabled = data.display.restartEnabled;
      orientation = data.display.orientation || 0;
    }

    const url = baseUrl + cmdSetProperty +
      "?" + paramRestartRequired + "=" + restartRequired +
      "&" + paramRebootRequired + "=" + rebootRequired +
      "&" + paramRebootTime + "=" + rebootTime +
      "&" + paramRebootEnabled + "=" + rebootEnabled +
      "&" + paramUpdateRequired + "=" + updateRequired +
      "&" + paramOrientation + "=" + orientation +
      "&" + _getTimestampParam();

    try {
      let command;

      if (updateRequired) { command = "update required"; }
      if (rebootRequired) { command = "reboot required"; }
      if (restartRequired) { command = "restart required"; }

      if (command) {
        Logger.logExternalMessage("player command", command);
        Logger.viewerInfo(`player command ${command}`);
      }
    } catch (err) {
      Logger.logException("RisePlayerController.setPlayerInfo", err);
    }

    _callUrlNative(url, cmdSetProperty);
  }
};

service.setDisplayCommand = function (isOff) {
  if (isActive) {
    const params = displayCommand + "=" + (isOff ? "off" : "on");
    const url = baseUrl + cmdSetProperty + "?" +
        params;

    _callUrlNative(url, params);
  }
};

let _pingPlayer = function () {
  if (isActive) {
    const url = baseUrl + cmdHeartbeat + "?callback=?";
    _callUrlNative(url, "ping");
    _pingV3Watchdog();

    if (!heartbeatInterval) {
      heartbeatInterval = setInterval(_pingPlayer, HEARTBEAT_TIME);
    }
  }
};

let _pingV3Watchdog = function () {
  if (!window.postToPlayer) {
    Logger.log("WARN - RisePlayerController._pingV3Watchdog: window.postToPlayer does not exist.");
  } else {
    try {
      window.postToPlayer({
        message: "ping",
        from: "viewer",
      });
    } catch (err) {
      Logger.logException("RisePlayerController._pingV3Watchdog", err);
    }
  }
};

module.exports = service;
