const Logger = require("../Logger");
const ConfigurationFactory = require("../ConfigurationFactory");

let url;

const service = {};

service.init = () => {
  if (window.disableViewerContentFetch) { return; }

  if (ConfigurationFactory.isEmbed()) { return; }
  if (ConfigurationFactory.isPreview()) { return; }

  setUrl();
};

const setUrl = () => {
  const host = "www.googleapis.com";
  let path = `/storage/v1/b/risevision-display-notifications/o/${ConfigurationFactory.getId()}%2fcontent.json`;
  let params = "?alt=media";

  if (ConfigurationFactory.isSharedschedule()) {
    path = `/storage/v1/b/risevision-schedule-notifications/o/${ConfigurationFactory.getId()}%2fsharedschedule.json`;
  }

  try {
    const gcsGeneration = localStorage.getItem("gcsGeneration");
    params = gcsGeneration && gcsGeneration !== "null" ? `${params}&ifGenerationNotMatch=${gcsGeneration}` : params;
  } catch (e) {
    Logger.logDebug("No local storage", e);
  }

  url = `https://${host}${path}${params}`;
};

service.getContent = (cb) => {
  if (!jQuery || !jQuery.getJSON) { return cb(); }
  if (!url) { return cb(); }

  Logger.logDebug("Retrieving GCS Data " + url);

  jQuery.getJSON(url, (result, textStatus, jqXHR) => {
    if (textStatus === "notmodified") {
      try {
        result = JSON.parse(localStorage.getItem("gcsResult"));
      } catch (e) {
        Logger.logExternalMessage("GCS data cache get fail", e.message);
        Logger.viewerError("E999999999", "GCS data cache get fail", e.message);
      }
    } else {
      try {
        const generation = jqXHR.getResponseHeader("x-goog-generation");
        localStorage.setItem("gcsResult", JSON.stringify(result));
        localStorage.setItem("gcsGeneration", generation);
        setUrl();
      } catch (e) {
        Logger.logExternalMessage("GCS data cache set fail", e.message);
        Logger.viewerError("E999999999", "GCS data cache set fail", e.message);
      }
    }

    cb(result);
  })
  .fail((jqXHR) => {
    const status = jqXHR && jqXHR.status,
      responseText = jqXHR && jqXHR.responseText;
    let result;

    Logger.logExternalMessage("GCS fetch fail", `status:${status} reason:${responseText} url: ${url}`);
    Logger.viewerError("E999999999", "GCS fetch fail", `status:${status} reason:${responseText} url: ${url}`);

    // check for network error
    if (status === -1 || status === 0) {
      try {
        result = JSON.parse(localStorage.getItem("gcsResult"));
      } catch (e) {
        Logger.logDebug("No local storage", e);
      }
    }

    cb(result);
  });
};

module.exports = service;
