const PlaylistItemInfo = {
  TYPE_PLAYLIST: "playlist",
  TYPE_URL: "url",
  TYPE_WIDGET: "widget",
  TYPE_PRESENTATION: "presentation",
  TYPE_HTML_TEMPLATE: "HTML Template",
  TYPE_GADGET: "gadget",
  TYPE_TEXT: "text",
  TYPE_IMAGE: "image",
  TYPE_VIDEO: "video",
  TYPE_HTML: "HTML",

  LOADED_STATUS: 1,
  READY_STATUS: 2,
};

module.exports = PlaylistItemInfo;
