const ConfigurationFactory = require("../ConfigurationFactory");
const Utils = require("../Utils");
const PlaylistItemInfo = require("../Info/PlaylistItemInfo");
const Logger = require("../Logger");

const factory = {};

const AUTH_KEY_PARAM = "CompanyAuthKey=";
const DISPLAY_ADDRESS_PARAM = "DisplayAddress=";

const sharedScheduleIncompatibleLayout = new RegExp([
  "widget-video-rv",
  "rise-twitter-widget",
  "widget-financial",
  "marketwall",
  "contentfinancial2",
  "Gadget-Financial",
  "\"type\": \"video\",",
  "t\\\\\"type\\\\\": \\\\\"video\\\\\","
].join("|"), "g");

let viewerData;

// ---------------- ViewerDataParser functionality ---------------//

factory.updateViewerData = (newViewerData) => {
  if (newViewerData && (!ConfigurationFactory.isDisplay() || newViewerData.signature)
/* && newViewerData.getStatusCode() != -1 */) {
    if (!viewerData || newViewerData.signature !== viewerData.signature) {
      viewerData = newViewerData;

      _rewriteS3Urls();

      if (ConfigurationFactory.isDisplay() && !location.protocol.toUpperCase().startsWith("HTTPS")) {
        _rewriteWidgetUrlProtocols();
      }

      return true;
    }
  }

  return false;
};

factory.getCompanyData = () => {
  return (viewerData && viewerData.company) || {};
};

factory.getDisplayAddress = () => {
  if (Utils.getFromQueryString(DISPLAY_ADDRESS_PARAM)) {
    return Utils.getFromQueryString(DISPLAY_ADDRESS_PARAM);
  }
  else if (viewerData.display && viewerData.display.displayAddress) {
    return JSON.stringify(viewerData.display.displayAddress);
  }
  return "";
};

factory.getAuthKey = () => {
  if (Utils.getFromQueryString(AUTH_KEY_PARAM)) {
    return Utils.getFromQueryString(AUTH_KEY_PARAM);
  }
  else if (viewerData.display && viewerData.display.authKey) {
    return viewerData.display.authKey;
  }

  return "";
};

factory.getSocialConnection = (network) => {
  if (viewerData.social && viewerData.social.length) {
    for (let i = 0; i < viewerData.social.length; i++) {
      if (viewerData.social[i].network === network) {
        return JSON.stringify(viewerData.social[i]);
      }
    }
  }
  return "";
};

// --------- ViewerDataController functionality -----------//
factory.getPollInterval = () => {
  return viewerData &&
viewerData.connection &&
viewerData.connection.pollInterval ?
viewerData.connection.pollInterval : 60;
};

factory.getPingInterval = () => {
  return viewerData &&
viewerData.connection &&
viewerData.connection.pingInterval ?
viewerData.connection.pingInterval : 30;
};

factory.hasData = () => {
  return viewerData && viewerData.content ? true : false;
};

factory.getSig = () => {
  return viewerData ? viewerData.signature : "";
};

factory.getEmbedItemData = (id, parentId) => {
// first look for the presentation
  if (id && viewerData && viewerData.content && viewerData.content.presentations) {
    for (let i = 0; i < viewerData.content.presentations.length; i += 1) {
      if (id === viewerData.content.presentations[i].id) {
// Return viewerData without the Schedule & Parent Presentation
        return _getEmbedItemData(parentId);
      }
    }
  }

  return null;
};

let _rewriteWidgetUrlProtocols = () => {
  const content = viewerData.content;

  if (!content || !content.presentations) {
    return;
  }

  Logger.logDebug("rewriting https protocol for widgets to http");

  const search = [
    RegExp("https://widgets.risevision.com/widget-", "g"),
    RegExp("https://s3.amazonaws.com/widget-", "g")
  ];

  const replace = [
    "http://widgets.risevision.com/widget-",
    "http://s3.amazonaws.com/widget-"
  ];

  search.forEach((regex, i) => {
    content.presentations.forEach(pres=>{
      if (!pres.layout) {return;}

      pres.layout = pres.layout.replace(regex, replace[i]);
    });
  });
}

let _rewriteS3Urls = () => {
  const content = viewerData.content;

  if (!content || !content.presentations) {
    return;
  }

  Logger.logDebug("rewriting s3 urls");

  const search = [
    RegExp("https?://s3.amazonaws.com/widget-", "g"),
    RegExp("https?://data-feed.digichief.com/risevision/weather/WeatherWidget.html", "gi"),
    RegExp("https?://account.testinseconds.com/WeatherWidget/widget.html", "gi"),
    RegExp("https://account.testinseconds.com/TextMarquee/widget.html", "gi"),
    RegExp("https://account.testinseconds.com/TrafficMapWidget/widget.html", "gi"),
    RegExp("http://www.scottsdigitalsignage.com/widget/youtube-widget/demo/index.html", "gi"),
    RegExp("https://data-feed.digichief.com/risevision/NewsRadar/NewsRadarWidget.html", "gi"),
    RegExp("http://data-feed.digichief.com/risevision/News/NewsWidget.html", "gi"),
    RegExp("https://rep.smartplayds.com/plugin/facebook-widget/widget.html", "gi"),
    RegExp("https://account.testinseconds.com/CountUpWidget/widget.html", "gi"),
    RegExp("https://account.testinseconds.com/CountdownWidget/widget.html", "gi"),
    RegExp("http://data-feed.digichief.com/risevision/Sports/SportsWidget.html", "gi"),
    RegExp("http://scottsdigitalsignage.com/widget/vimeo-widget/demo/index.html", "gi")
  ];

  const replace = [
    "https://widgets.risevision.com/widget-",
    "https://widgets.risevision.com/widget-digichief-weather/WeatherWidget.html",
    "https://widgets.risevision.com/widget-computer-aid-weather/widget.html",
    "https://widgets.risevision.com/widget-computer-aid-marquee/widget.html",
    "https://widgets.risevision.com/widget-computer-aid-traffic/widget.html",
    "https://widgets.risevision.com/widget-youtube/demo/index.html",
    "https://widgets.risevision.com/widget-newsradar/NewsRadarWidget.html",
    "https://widgets.risevision.com/widget-news/NewsWidget.html",
    "https://widgets.risevision.com/widget-facebook/widget.html",
    "https://widgets.risevision.com/widget-computer-aid-count-up/widget.html",
    "https://widgets.risevision.com/widget-computer-aid-count-down/widget.html",
    "https://widgets.risevision.com/widget-sports/SportsWidget.html",
    "https://widgets.risevision.com/widget-vimeo/demo/index.html"
  ];

  search.forEach((regex, i) => {
    content.presentations.forEach(pres=>{
      if (!pres.layout) {return;}

      pres.layout = pres.layout.replace(regex, replace[i]);
    });
  });
};

let _getEmbedItemData = (id) => {
  try {
// make a copy of the object
    const newObject = jQuery.extend(true, {}, viewerData);
    const content = newObject.content || newObject;

    if (content !== null) {
      delete content.schedule;

      if (content.presentations) {
        for (let i = 0; i < content.presentations.length; i += 1) {
          if (content.presentations[i].id === id) {
            content.presentations.splice(i, 1);
          }
        }
      }
    }

    return newObject;
  }
  catch (err) {
    Logger.logException("ViewrDataInfo.getEmbedItemData", err);
    return {};
  }
};

// --------------- ViewerDataController JAVA functionality --------//

let itemName, itemId, itemCompanyId;
let template;

const _getDummySchedule = () => {
  const schedule = {};
  const content = viewerData.content;

  schedule.id = ConfigurationFactory.SCHEDULE;
  schedule.timeDefined = false;
  schedule.items = [];

  if (content && content.presentations && content.presentations.length > 0) {
    let index = 0;
    for (let i = 0; i < content.presentations.length; i += 1) {
      if (content.presentations[i] != null && content.presentations[i].id === ConfigurationFactory.getId()) {
        index = i;

        break;
      }
    }

    const item = {};
    item.objectReference = content.presentations[index].id;
    item.type = PlaylistItemInfo.TYPE_PRESENTATION;
    item.presentationType = content.presentations[index].presentationType;
    item.duration = 10;
    item.playUntilDone = true;
    item.timeDefined = false;
    schedule.items.push(item);

    itemName = item.name;
    itemId = item.id;
    itemCompanyId = content.presentations[index].companyId;
    template = item.template;
  } else {
    return null;
  }

  return schedule;
};

factory.getSchedule = () => {
  const content = viewerData.content;

  if (content && content.schedule) {
    itemId = content.schedule.id;
    itemName = content.schedule.name;
    itemCompanyId = content.schedule.companyId;

    return content.schedule;
  } else if (content && content.presentations) {
    return _getDummySchedule();
  }

  return null;
};

factory.getPresentation = (id) => {
  const content = viewerData.content;

  if (content && content.presentations) {
    for (let j = 0; j < content.presentations.length; j += 1) {
      if (content.presentations[j].id === id) {
        const presentation = jQuery.extend(true, {}, content.presentations[j]);

        return presentation;
      }
    }
  }
  return null;
};

factory.getSharedScheduleIncompatible = (item) => {
  if (!item) return [];
  if (!item.objectReference) return [];

  const pres = factory.getPresentation(item.objectReference);
  if (!pres || !pres.layout) return [];

  return pres.layout.match(sharedScheduleIncompatibleLayout) || [];
};

factory.getItemName = () => { return itemName; };

factory.getItemId = () => { return itemId; };

factory.getItemCompanyId = () => { return itemCompanyId; };

factory.isTemplate = () => { return template; };


module.exports = factory;
