const Global = require("./Config/Global");
const ConfigurationFactory = require("./ConfigurationFactory");
const AnalyticsFactory = require("./AnalyticsFactory");
const MachineId = require("./Machine-id");
const Logger = require("./Logger");
const ContentSentinelDispatcher = require("content-sentinel");
const Utils = require("./Utils");
const PlayerController = require("./Player/RisePlayerController");

const service = {};

const PLAYER_PARAM = "player=";

let scheduleController, newScheduleController;
let onDataReady;

let isShowingBlack = false, isShowingProgressBar = true;

const _preparePlayer = () => {
  if (!ConfigurationFactory.isDisplay()) { return; }

  ConfigurationFactory.displayLogging();

  PlayerController.setIsActive(Utils.getFromQueryString(PLAYER_PARAM));
};

const _registerServiceWorker = () => {
  RiseVision.Viewer.ServiceWorker.register(() => {
    _load();
  });
};

const _load = () => {
  _preparePlayer();

  // check if no Display ID
  if (PlayerController.getIsActive() && ConfigurationFactory.isDisplay() && !ConfigurationFactory.isEmbed() &&
    ConfigurationFactory.DEMO_ID === ConfigurationFactory.getId()) {
    if (ConfigurationFactory.getClaimId()) {
      RiseVision.Viewer.UI.DisplayRegister.EnterClaimIdWidget.show(false);
    } else {
      RiseVision.Viewer.UI.DisplayRegister.DisplayRegisterWidget.show("DISPLAY_ID_NULL");
    }
  } else {
    RiseVision.Viewer.Messaging.init(() => {
      RiseVision.Viewer.Data.GCSController.init();
      RiseVision.Viewer.LocalMessaging.init(() => {
        service.loadPresentation();
      });
    });
  }
};

service.init = () => {
  ConfigurationFactory.updateParameters();

  Logger.initEndpointLogger({
    endpointId: ConfigurationFactory.getDisplayId() || Utils.getUniqueId(),
    endpointType: ConfigurationFactory.endpointType(),
    endpointUrl: window.location.href,
    scheduleId: ConfigurationFactory.getScheduleId(),
    embedReferralUrl: ConfigurationFactory.isEmbedSharedSchedule() ? document.referrer : null,
    viewerVersion: Global.VIEWER_VERSION
  });

  Logger.setEndpointLoggerContentFields({ scheduleId: ConfigurationFactory.getScheduleId() });

  Logger.recordUptimeHeartbeat();
  setInterval(Logger.recordUptimeHeartbeat, Logger.heartbeatInterval());

  const csPromise = ConfigurationFactory.useContentSentinel() ? ContentSentinelDispatcher.init({
    displayId: RiseVision.Viewer.ConfigurationFactory.getDisplayId(),
    machineId: MachineId.id(),
    heartbeatInterval: RiseVision.Viewer.Logger.heartbeatInterval(),
    heartbeatLogFn: RiseVision.Viewer.Logger.recordUptimeHeartbeat,
    logFn: RiseVision.Viewer.Logger.logTemplateEvent,
    endpointType: RiseVision.Viewer.ConfigurationFactory.endpointType(),
    endpointId: RiseVision.Viewer.ConfigurationFactory.getDisplayId() || RiseVision.Viewer.Utils.getUniqueId(),
  }) : Promise.resolve();

  ConfigurationFactory.isDisplay() ? setTimeout(()=>csPromise.then(_registerServiceWorker), Global.DELAY_FOR_INJECTED_SCRIPTS) : csPromise.then(_registerServiceWorker);
};

service.loadPresentation = () => {
  if (ConfigurationFactory.isDisplay()) {
    RiseVision.Viewer.Cache.RiseCacheController.pingCache();
  }

  if (ConfigurationFactory.getId() && ConfigurationFactory.isValidType()) {
    RiseVision.Viewer.Data.ViewerDataController.init(_onDataReady);
  }
};

function _onDataReady() {
  if (newScheduleController) {
    newScheduleController.unload();
  }

  let onScheduleDone = null;
  if (ConfigurationFactory.isEmbed()) {
    onScheduleDone = _reportDone;
  }

  newScheduleController = new RiseVision.Viewer.Content.ScheduleController(_onScheduleReady, onScheduleDone);
  newScheduleController.init();

  if (ConfigurationFactory.isPreview() && ConfigurationFactory.getShowUi()) {
    RiseVision.Viewer.UI.ViewerPreviewWidget.show();
  }

  AnalyticsFactory.init();
}

function _onScheduleReady() {
  const oldScheduleController = scheduleController;

  scheduleController = newScheduleController;

  // Reset the newScheduleController parameter
  newScheduleController = null;

  if (ConfigurationFactory.isEmbed()) {
    _reportReady();
  }
  else {
    _playSchedule();
  }

  if (oldScheduleController) {
    oldScheduleController.unload();
  }

  AnalyticsFactory.trackLoadTime();
}

function _playSchedule() {
  if (isShowingProgressBar) {
    Utils.showElement("progress", false);

    isShowingProgressBar = false;
  }

  if (!scheduleController.play()) {
    _reportDone();
  }
}

service.showBlackScreen = function (show) {
  if ((ConfigurationFactory.isSharedschedule() || ConfigurationFactory.isDisplay()) && isShowingBlack !== show) {
    isShowingBlack = show;
    Utils.setBackground("mainDiv", show ? "black" : "transparent");

    PlayerController.setDisplayCommand(show);
  }
};

service.isShowingProgressBar = function () {
  return isShowingProgressBar;
};

function _reportReady() {
  try {
    window.embedReady();
  } catch (err) {
    Logger.logException("EntryPoint._reportReady", err);
  }
}

function _reportDone() {
  try {
    window.embedDone();
  } catch (err) {
    Logger.logException("EntryPoint._reportDone", err);
  }
}

service.embedPlay = function () {
  _playSchedule();
};

service.embedPause = function () {
  scheduleController.pause();
};

service.embedStop = function () {
  service.embedPause();
};

module.exports = service;
