"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var __assign = Object.assign;

var __commonJS = function __commonJS(callback, module2) {
  return function () {
    if (!module2) {
      module2 = {
        exports: {}
      };
      callback(module2.exports, module2);
    }

    return module2.exports;
  };
}; // src/heartbeat-monitor.js


var require_heartbeat_monitor = __commonJS(function (exports2, module2) {
  var version2 = false ? "" : "1.2.0";
  var countdownTimer = null;
  var heartbeatLogFn = null;
  var heartbeatInterval = null;
  var log2 = null;

  var startCountdown = function startCountdown() {
    countdownTimer = setTimeout(function () {
      log2({
        severity: "error",
        eventApp: "content-sentinel",
        eventErrorCode: "E999999999",
        eventDetails: "Missing Content Sentinel heartbeat"
      });
      startCountdown();
    }, heartbeatInterval * 1.1);
  };

  module2.exports = {
    init: function init(hbLogFn, logFn, interval) {
      module2.exports.setHeartbeatLogFn(hbLogFn);
      module2.exports.setLogFn(logFn);
      module2.exports.startMonitoring(interval);
    },
    startMonitoring: function startMonitoring(interval) {
      heartbeatInterval = interval;
      startCountdown();
    },
    setHeartbeatLogFn: function setHeartbeatLogFn(fn) {
      heartbeatLogFn = fn;
    },
    setLogFn: function setLogFn(fn) {
      log2 = fn;
    },
    heartbeatReceived: function heartbeatReceived() {
      heartbeatLogFn({
        eventApp: "content-sentinel",
        eventAppVersion: version2
      });
      clearTimeout(countdownTimer);
      startCountdown();
    },
    stopMonitoring: function stopMonitoring() {
      clearInterval(countdownTimer);
    }
  };
}); // src/dispatcher.js


var heartbeatMonitor = require_heartbeat_monitor();
var version = false ? "" : "1.2.0";
var fetcherPath = version ? "./content-sentinel/fetcher-".concat(version, ".js") : "fetcher.js";
var fetcherScope = fetcherPath.split("/").slice(0, -1).join("/");
var controllerBridgePath = version ? "./content-sentinel/controller-bridge-".concat(version, ".html") : "controller-bridge.html";
var controllerPath = version ? "./controller-".concat(version, ".js") : "controller.js";
var controllerBridge = null;
var logger = null;
var defaultLogData = {
  eventAppVersion: version,
  eventApp: "content-sentinel",
  severity: "DEBUG",
  eventDetails: ""
};

var log = function log(data) {
  return logger && logger(__assign(__assign({}, defaultLogData), data));
};

var receivers = null;

var handleMessageError = function handleMessageError(_ref) {
  var origin = _ref.origin,
      data = _ref.data;
  log({
    severity: "error",
    eventErrorCode: "E999999999",
    eventDetails: "message error",
    debugInfo: JSON.stringify({
      origin: origin,
      data: data
    })
  });
};

var routeMessage = function routeMessage(event) {
  if (event.data.from === "content-consumer") {
    module.exports.sendControllerMessage(event.data, event.source || event.srcElement);
    return;
  }

  if (event.data.from === "content-sentinel-controller") {
    receivers.forEach(function (receiver) {
      if (!receiver || !receiver.postMessage) {
        return;
      }

      receiver.postMessage(event.data, location.origin);
    });
  }

  if (!event.data.from || event.data.from !== "content-sentinel-controller") {
    controllerBridge.contentWindow.postMessage(event.data, location.origin);
  }
};

var handleMessage = function handleMessage(event) {
  if (!event || !event.data) {
    return;
  }

  if (event.data.msg === "content-sentinel-log") {
    log(event.data.logEntry);
    return;
  }

  if (event.data.msg === "content-sentinel-heartbeat") {
    heartbeatMonitor.heartbeatReceived();
    return;
  }

  routeMessage(event);
};

var handleUnhandledRejection = function handleUnhandledRejection(event) {
  console.log(event);
};

var addEventListeners = function addEventListeners() {
  addEventListener("message", handleMessage);
  addEventListener("messageerror", handleMessageError);
  addEventListener("unhandledrejection", handleUnhandledRejection);
};

var registerFetcher = function registerFetcher() {
  console.log("Reregistering fetcher");

  if (!navigator || !navigator.serviceWorker) {
    var errorCode = "E000000102";
    log({
      severity: "error",
      eventErrorCode: errorCode,
      eventDetails: "Service worker not supported"
    });
    return Promise.reject(Error(errorCode));
  }

  navigator.serviceWorker.onerror = function (error) {
    log({
      severity: "error",
      eventErrorCode: "E999999999",
      eventDetails: "Service worker onerror fired"
    });
    console.error("Service worker onerror fired", error);
  };

  return navigator.serviceWorker.getRegistration(fetcherScope).then(function (reg) {
    return reg && reg.unregister();
  }).then(function () {
    return navigator.serviceWorker.register(fetcherPath);
  });
};

var startController = function startController() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      displayId = _ref2.displayId,
      machineId = _ref2.machineId,
      heartbeatInterval = _ref2.heartbeatInterval,
      endpointType = _ref2.endpointType,
      endpointId = _ref2.endpointId,
      scheduleId = _ref2.scheduleId;

  var search = "?interval=".concat(heartbeatInterval, "&controllerPath=").concat(controllerPath, "&scheduleId=").concat(scheduleId, "&endpointType=").concat(endpointType, "&endpointId=").concat(endpointId || displayId);

  if (displayId) {
    search = "".concat(search, "&displayId=").concat(displayId, "&machineId=").concat(machineId);
  }

  controllerBridge = document.createElement("iframe");
  controllerBridge.src = controllerBridgePath + search;
  controllerBridge.style.display = "none";
  return new Promise(function (res) {
    controllerBridge.addEventListener("load", res);
    document.body.append(controllerBridge);
    setTimeout(res, 2e3);
  });
};

module.exports = {
  init: function init() {
    var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        displayId = _ref3.displayId,
        machineId = _ref3.machineId,
        heartbeatInterval = _ref3.heartbeatInterval,
        heartbeatLogFn = _ref3.heartbeatLogFn,
        logFn = _ref3.logFn,
        endpointId = _ref3.endpointId,
        scheduleId = _ref3.scheduleId,
        endpointType = _ref3.endpointType;

    if ((typeof window === "undefined" ? "undefined" : _typeof(window)) === "object" && window) {
      window.contentSentinelInitializer = true;
    }

    if (logFn) {
      module.exports.setLogFn(logFn);
    }

    if (controllerBridge) {
      log({
        severity: "error",
        eventErrorCode: "E999999999",
        eventDetails: "Initialized dispatcher multiple times"
      });
    }

    heartbeatMonitor.init(heartbeatLogFn, logFn, heartbeatInterval);
    receivers = new Set();
    return registerFetcher().then(function () {
      return startController({
        displayId: displayId,
        machineId: machineId,
        heartbeatInterval: heartbeatInterval,
        endpointId: endpointId,
        scheduleId: scheduleId,
        endpointType: endpointType
      });
    }).then(addEventListeners)["catch"](function (error) {
      if (error && error.message && /^E\d{9}$/.test(error.message)) {
        return;
      }

      log({
        severity: "error",
        eventErrorCode: "E999999999",
        eventDetails: "error during init",
        debugInfo: JSON.stringify({
          msg: error.message,
          stack: error.stack
        })
      });
    });
  },
  clearReceivers: function clearReceivers() {
    receivers.clear();
  },
  sendControllerMessage: function sendControllerMessage(msg, receiver) {
    if (!receiver) {
      return log({
        severity: "error",
        eventErrorCode: "E999999999",
        eventDetails: "invalid use of dispatcher - missing receiver"
      });
    }

    if (receiver !== window) {
      receivers.add(receiver);
    }

    return controllerBridge.contentWindow.postMessage(msg, location.origin);
  },
  setLogFn: function setLogFn(fn) {
    logger = fn;
  }
};