const Logger = require("../Logger");
const Utils = require("../Utils");
const ViewerDataInfo = require("../Data/ViewerDataInfo");
const PlaylistItemInfo = require("../Info/PlaylistItemInfo");
const HTMLTemplateRenderer = require("../Renderer/HTMLTemplateRenderer");
const HTMLTemplateMessaging = require("../Renderer/HTMLTemplateMessaging");
const TransitionHandler = require("../Renderer/TransitionHandler");
const AnalyticsFactory = require("../AnalyticsFactory");

const HTMLTemplateController = function (item, htmlName, containerName,
  onPresentationReady, onPresentationDone) {
  const factory = {};

  let status;
  let isPlaying = false;
  let firstPlayback = true;
  let iframeName;
  let presentation;
  const readyListeners = [onPresentationReady];

  const _onPresentationDoneIfPlaying = () => {
    if (isPlaying && onPresentationDone) {
      onPresentationDone();
    }
  };

  const htmlTemplateMessaging = new HTMLTemplateMessaging(_onPresentationDoneIfPlaying, htmlName);
  const transitionHandler = new TransitionHandler(containerName, htmlName, item.transitionType);

  let readyTimer;

  factory.init = (load) => {
    if (status === HTMLTemplateController.LOADING_STATUS) {
      presentation = ViewerDataInfo.getPresentation(item.objectReference);

      if (!presentation) { return; }

      if (onPresentationDone) {
        htmlTemplateMessaging.addListenerForTemplatePlayUntilDone();
      }
      _addPresentation();

      htmlTemplateMessaging.onRiseComponentsReady(_onRiseComponentsReady, _onTemplateError);
    }
    // if this presentation is a multiple, init has already executed for another instance
    else if (load) {
      _addPresentation();
    }
  };

  function _addPresentation() {
    HTMLTemplateRenderer.render(presentation, htmlName, containerName);

    // Timer that will cut loading to a maximum of 20 seconds.
    readyTimer = setTimeout(_forceDataReady, 20 * 1000);

    status = HTMLTemplateController.ADDED_STATUS;
  }

  function _onRiseComponentsReady() {
    if (status === HTMLTemplateController.ADDED_STATUS) {
      clearTimeout(readyTimer);
      status = HTMLTemplateController.READY_STATUS;
      _presentationReady();
    }
  }

  function _onTemplateError() {
    if (status === HTMLTemplateController.ADDED_STATUS) {
      clearTimeout(readyTimer);
      status = HTMLTemplateController.ERROR_STATUS;
      Utils.destroyElement(htmlName, containerName);

      _presentationReady();
    }
  }

  function _forceDataReady() {
    status = HTMLTemplateController.READY_STATUS;
    _presentationReady();
  }

  function _presentationReady() {
    Logger.logDebug(`HTML Template - ${htmlName} - Ready!`);

    readyListeners.forEach((listener) => { return listener(); });
  }

  function _trackPlayback() {
    if (firstPlayback) {
      AnalyticsFactory.trackPlayback(item);

      firstPlayback = false;
    }
  }

  factory.play = () => {
    Logger.logDebug(`HTML Template ${htmlName} - Play!`);

    _trackPlayback();

    if (!isPlaying) {
      htmlTemplateMessaging.sendPresentationEvent("rise-presentation-play");

      // _setVisibility(true);
      transitionHandler.show();
    }

    isPlaying = true;
  };

  factory.appendOnPresentationReady = (fn) => {
    if (!fn) { return; }

    readyListeners.push(fn);
  };

  factory.stop = () => {
    Logger.logDebug(`HTML Template ${htmlName} - Stop!`);

    htmlTemplateMessaging.sendPresentationEvent("rise-presentation-stop");

    if (isPlaying) {
      isPlaying = false;

      // _setVisibility(false);
      transitionHandler.hide();
    }
  };

  factory.pause = () => {
    Logger.logDebug(`HTML Template ${htmlName} - Pause!`);

    htmlTemplateMessaging.sendPresentationEvent("rise-presentation-stop");

    if (isPlaying) {
      isPlaying = false;

      // AD: Added visibility = false so pause can be called between presentations switching
      // _setVisibility(false);
      transitionHandler.hide();
    }
  };

  // function _setVisibility(visible) {
  //   if (visible) {
  //     window.showElement(htmlName);
  //   } else {
  //     window.hideElement(htmlName);
  //   }
  // }

  factory.isReady = () => {
    return status == HTMLTemplateController.READY_STATUS;
  };

  factory.getStatus = () => {
    return status;
  };

  factory.load = () => {
    if (status === HTMLTemplateController.UNLOADED_STATUS) {
      _addPresentation();
    }
  };

  factory.unload = () => {
    // only unload READY presentations that are NOT playing
    if (status >= HTMLTemplateController.READY_STATUS && !isPlaying) {
      status = HTMLTemplateController.UNLOADED_STATUS;

      Utils.destroyElement(htmlName, containerName);
    }
  };

  factory.clearInstance = () => {
    htmlTemplateMessaging.removeListenerForTemplatePlayUntilDone();
  };

  (function () {
    status = HTMLTemplateController.LOADING_STATUS;
  }());

  return factory;
};

HTMLTemplateController.ERROR_STATUS = -1;
HTMLTemplateController.UNLOADED_STATUS = 1;
HTMLTemplateController.LOADING_STATUS = 2;
HTMLTemplateController.ADDED_STATUS = 3;
HTMLTemplateController.READY_STATUS = 4;

HTMLTemplateController.UNLOAD_TIME = 1 * 60 * 1000;

module.exports = HTMLTemplateController;
